.ui.menu.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
}
.ui.menu.sidebar .item {
  border: none;
  border-bottom: none;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 0 10px 0;
  padding: 12px 15px;
  flex-shrink: 0;
}
.ui.menu.sidebar .sidebar-menu-header {
  font-size: 1.2em;
  font-family: "graphik-medium";
  padding: 0 0 20px 0;
  margin: 10px 0 10px 0;
  background: none !important;
}
.ui.menu.sidebar .menu-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
}
.ui.menu.sidebar .menu-item-text {
  display: flex;
  flex-direction: column;
}
.ui.menu.sidebar .sub-text {
  color: white;
  font-size: 0.8em;
  margin-top: 5px;
}
.ui.menu.sidebar .sub-item {
  padding-left: 0px;
  padding-right: 0;
  margin: 0;
}
.ui.menu.sidebar .sub-item:first-child {
  margin-top: 15px;
}
.ui.menu.sidebar .expander .menu-sub-items {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-out;
}
.ui.menu.sidebar .expander.expanded .menu-sub-items {
  max-height: 500px;
}
.ui.menu.sidebar .sidemenu-row {
  display: flex;
  justify-content: space-between;
}
.ui.menu.sidebar .sidemenu-row .item {
  flex: 0.48 2;
  padding: 12px 15px;
}
.ui.menu.sidebar .toggle-button .menu-item-content {
  flex-wrap: wrap;
}
.ui.menu.sidebar .toggle-button .menu-item-content .menu-item-text {
  order: 2;
  width: 100%;
  margin-top: 15px;
}
.ui.menu.sidebar .aurora-energy-section {
  text-align: left;
  padding: 10px 0;
}
.ui.menu.sidebar .aurora-energy-section p {
  margin-bottom: 5px;
}
.ui.menu.sidebar .aurora-energy-section small {
  display: block;
  margin-top: 5px;
}

a.item.businessLightDarkToggle {
  flex: none !important;
  width: 100% !important;
}


