.ui.segment.power-hours-intro {

	max-width: 24em;
	margin: auto auto 0.5rem;
}

h5 {
	font-size: 1.1em;
	line-height: 1.4285em;
}


.ui.modal.power-hours-confirmation-modal {
	$border-radius: 10px;
	text-align: center;
	background: #0060AE;
	color: white;
	border-radius: $border-radius;

	& > .header {
		padding-top: 4rem!important;
		margin-bottom: 0;
		border-top-left-radius: $border-radius;
		border-top-right-radius: $border-radius;
		border-bottom: none;
	}

	& > .content {
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
		padding-bottom: 1.5rem!important;
	}

	h2 {
		margin-top: 5px;
	}

	p {
		font-size: 1.2em;
		line-height: 1.2;
		margin-bottom: 0.3em;
		
		&.address {
			font-family: "graphik"
		}
		&.confirmheader {
			font-family: "graphik-medium"
		}
	}

	.period {
		text-transform: lowercase;
	}

	a {
		color: inherit;
		font-weight: bold;
		text-decoration: underline;
	}

	// Checkbox
	label.terms {
		max-width: 270px;
		margin: 3rem auto 0;
		display: flex;
		.field {
			flex: 0 0 1.5em;
			text-align: left;
			padding-top: 0.2em;
		}
		.ui.checkbox label:before {
			border-radius: 0;
			border-color: #0060ae;
		}
		.ui.checkbox input:checked~label:before {
			background: white;
		}
		.ui.checkbox input:checked~label:after {
			color: #0060ae;
		}
		span {
			text-align: left;
		}
	}

	.button {
		height: 46px;
		border-radius:  4px;
		max-width: 300px;
		margin: 0 auto 0;
	}

}