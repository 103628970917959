.signup-faq {

	margin-top: 50px;

	font-size: 13px;

	h3 {
		font-weight: 400;
		color: #4a4a4a;
		margin-bottom: 30px;
	}

	.accordion.ui {
		
		border-bottom: 1px solid rgba(184, 181, 181, 0.5);	

		.title {
			font-size: inherit;
			position: relative;
			border-top: 1px solid rgba(184, 181, 181, 0.5);	
			padding: 20px 45px 20px 0;

			.icon {
				position: absolute;
				right: 5px;
				top: 50%;
				transform-origin: 50% 50%;
				transform: translate(-50%, -50%);
				margin: 0;

				&:before {
					content: '';
					display: block;
					position: absolute;
					width: 1em;
					height: 1em;
					border-right: 1px solid #000;
					border-bottom: 1px solid #000;
					left: 50%;
					top: 50%;
					transform-origin: 50% 50%;
					transform: translate(-50%, -75%) rotate(45deg);
				}
			}
			&.active .icon {
				transform: translate(-50%, -50%) rotate(180deg);

			}

		}

		.content {
			font-size: inherit;
			margin: -5px 45px 15px 15px;
		}

		p {
			font-size: inherit;
		}

	}

}