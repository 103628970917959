.ui.two.cards > .solid-card-group {
  margin: 0.75em 1em;
  width: calc(50% - 2em);
  color: #ffffff;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  max-height: 177px;
  display: flex;
}
@media only screen and (max-width: 767px) {
  .ui.two.cards > .solid-card-group {
    width: calc(100% - 2em);
  }
}
.ui.two.cards > .solid-card-group .ui.card.solid-card {
  flex: 1 1 0;
  margin: 0;
}
.ui.two.cards > .solid-card-group .ui.card.solid-card:not(:last-child) {
  margin-right: 1em;
}
@media only screen and (max-width: 767px) {
  .ui.two.cards > .solid-card-group .ui.card.solid-card:not(:last-child) {
    margin-right: 1em;
  }
}

.ui.card.solid-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 17px 15px 11px;
  border-radius: 5px;
  font-size: 14px;
  min-height: 150px;
}
.ui.card.solid-card.power-hours-card {
  background: linear-gradient(217deg, #FC7D00 50.17%, #F7902B 90.32%);
}
.ui.card.solid-card.avg-usage-card {
  background: linear-gradient(180deg, #0060AE 0%, #5580A3 100%);
}
.ui.card.solid-card a {
  color: inherit;
}

.solid-card-header {
  display: flex;
  justify-content: space-between;
  line-height: 1.2;
  font-family: graphik-medium;
  text-align: left;
  padding-bottom: 8px;
}
.solid-card-header.power-hours-header {
  color: white;
  padding: 0px;
}
.solid-card-header.avg-usage-header {
  color: white;
  padding: 0px;
}

.solid-card-content {
  flex: 1 1 0;
  align-content: center;
}
.solid-card-content span.value {
  font-family: graphik-medium;
}
.solid-card-content span.loading {
  font-family: graphik-medium;
  color: #ffffff;
  animation: fadeInOut 3s ease-in-out infinite; /* Adjust duration as needed */
}
.solid-card-content span.pending {
  font-family: graphik-medium;
  color: #ffffff;
}
@keyframes fadeInOut {
  0% {
    opacity: 0; /* Fully transparent */
  }
  50% {
    opacity: 1; /* Fully opaque */
  }
  100% {
    opacity: 0; /* Fully transparent again */
  }
}

.solid-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: auto;
}
.solid-card-footer.power-hours-footer {
  color: white;
}
.solid-card-footer.avg-usage-footer {
  color: white;
}


