.ui.checkbox.signup-checkbox {
	
	$box-size: 28px;

	margin: 0 0 1em;

	label {
		padding-left: $box-size + 10px;
		display: flex;
		min-height: $box-size + 10px;
		font-size: 15px;

		.signup-checkbox-label-inner {
			margin: auto 0;
		}

		&:before {
			top: 5px;
			border-radius: 5px;
			border-color: #A09B9B;
			width: $box-size;
			height: $box-size;
		}

		&:after {
			top: 5px;
			font-size: 1.1em;
			line-height: $box-size;
			width: $box-size;
			height: $box-size;
		}
	}

	&.small label {
		padding-left: $box-size + 15px;
		font-size: 0.8666em;
		line-height: 2.1;
	}

}