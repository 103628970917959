.main-header.ui.menu {
	border-color: transparent !important;
	border-bottom-color: rgba(255, 255, 255, 0.3) !important;
	border-bottom-width: 1px !important;
  
	&.blue-header {
		background: #0060AE;
		svg g {
			fill: white !important;
		}
	}

	.item {
	  padding: 1.75em 0.8em;
	  height: 88px;
	  display: flex;
	  align-items: flex-end;
  
	  &:first-child {
		padding-left: 0;
	  }
	  &:last-child {
		padding-right: 0;
	  }
	  
	  &.right {
		svg {
		  margin: 0 0 0 10px;
		}
	  }
	}
  
	.item.header + .item {
	  margin-left: 1.5rem;
	}
  
	.nav-icon {
	  padding: 0.4em 0 0 01em;
	  margin: 0 0.5em;  // Adjust margins for equal spacing
	  display: flex;
	  align-items: center;
  
	  svg {
		height: 24px;  // Ensure consistent size
		width: 24px;
		margin: 0;     // Reset margin for consistency
	  }
	}
  
	// CSS for the specific menu items
	.main-header .menu-item-icon {
	  font-size: 90%;  // Reduce text size by 10%
	  display: flex;
	  align-items: center;
	}
  
	a.item.menu-item-icon > span {
	  margin-left: 8px;  // Explicitly set the margin between the icon and text
	  font-size: 0.85em;
	}
  
	.right.menu>.fresnel-container {
	  display: flex;
	}
  
	.ui.checkbox label {
	  color: white !important;
	}
  
	.header.item {
	  align-self: baseline !important;
	  background: none;
	  &.active {
		background: none;
	  }
	}
  
	&.mobile {
	  height: 61px;
	}
  
	&.compressed {
	  &.pointing {
		border-width: 0;
	  }
  
	  a.item {
		height: 88px;
		font-size: 12px;
		line-height: 109.5%;
		text-align: center;
		flex-direction: column;
		justify-content: center;
  
		&:not(.nav-icon) {
		  padding-left: 0 !important;
		  padding-right: 0 !important;
		  width: 110px;
		  >svg {
			margin: 0 0 1em 0;
		  }
		}
  
		&.header {
		  padding: 10px 0px !important;
		  flex-direction: row;
		}
  
		&.active {
		  font-weight: normal;
		}
	  }
  
	  .product-picker {
		margin-left: auto;
		display: flex;
  
		.item {
		  color: white !important;
		  width: 100px;
  
		  &.active {
			background: rgba(51, 51, 51, 0.1);
			mix-blend-mode: multiply;
			box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.25);
			border-color: transparent;
		  }
		}
	  }
	}
  
	&.mobile {
	  margin: 0;
	  height: 61px;
	  padding: 0 12px;
  
	  .header.item {
		height: 61px;
		display: flex;
    	align-items: center;
		margin-left: 0.3rem!important;
  
		img {
		  height: 32px;
		}
  
		.icon {
		  top: 0;
		  height: 61px;
		  line-height: 61px;
		}
		.item.header + .item {
			margin-left: 0rem;
		  }
	  }
  
	  a.item {
		color: inherit !important;
		height: 61px;
		display: inline-flex;
		margin-left: 0rem!important;
	  }
  
	  &.compressed {
		.product-picker {
		  width: 100%;
		  justify-content: center;
  
		  .item {
			margin: 0;
			padding: 0 !important;
			width: calc(50%) !important;
			height: 77px !important;
			flex-direction: row;
			border-radius: 0;
  
			&:first-child:not(.header) {
			  border-right: 1px solid rgba(255, 255, 255, 0.3);
			  border-radius: 0;
			}
  
			>svg {
			  margin: 0 0 0 2em;
			}
		  }
		}
	  }
  
	  & + .menu {
		height: 77px;
	  }
	}
  
	.right-icons {
	  display: flex;
	  align-items: center;
	}
  
	.white-page .main-header.ui.menu {
	  background: #0060AE;
	  border-radius: 0;
	}
  

  }  
  .count {
    border-radius: 50%;
    background: #FF4949;
    width: 1rem;
    height: 1rem;
    font-size: 0.75em;
    color: white;
    text-align: center;
    padding: 0.125rem;
    margin-left: -1em;
  }