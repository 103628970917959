.password-validation-info {
  padding-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 20px;

  text-align: left;
  border-bottom: solid rgba(0, 0, 0, 0.08) 1px;

  i {
    float: right;
    color: #999999;

    &.checked {
      color: green;
    }
  }

  &.white {
    ul {
      li:before {
        color: white;
      }
    }

    i {
      color: rgba(255, 255, 255, 0.2);

      &.checked {
     
      }
    }
  }
}
