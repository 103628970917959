.journey-progress-banner {
  padding: 1.3em 1.5em;
  background: #f2f2f2;
}
.journey-progress-banner p {
  color: #4a4a4a;
  font-size: 20px;
  line-height: 1.6;
  font-weight: 500;
}
.journey-progress-banner.error p {
  color: #E10000;
}

.journey-progress-aside {
  height: 100%;
  background: #f2f2f2;
  margin-left: 20px;
  padding: 120px 0;
}

.journey-progress-step {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 0;
}
.journey-progress-step svg {
  width: 25px;
  height: 25px;
}
.journey-progress-step:not(.checked) {
  background: #D9D9D9;
  border-radius: 50%;
}
.journey-progress-step:not(.checked).active {
  background: #A09B9B;
}
.journey-progress-step:not(:last-of-type) {
  margin-right: 25px;
}
.journey-progress-step:not(:last-of-type):after {
  content: "";
  position: absolute;
  left: 100%;
  top: 50%;
  border-top: 1px solid #D9D9D9;
  margin-top: 0.5px;
  width: 25px;
}


