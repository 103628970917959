.confirmfooter {
  font-size: 10px;
}

.ui.card.power-hours-card.power-hours-summary {
  margin: 20px 0 10px 0;
}
.ui.card.power-hours-card.power-hours-summary .summary-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 22px 20px 20px 15px;
  font-family: graphik-medium;
  font-size: 14px;
  line-height: 1.1em;
  text-align: left;
}
.ui.card.power-hours-card.power-hours-summary .summary-header svg {
  width: 150px;
}
.ui.card.power-hours-card.power-hours-summary .summary-info {
  font-size: 13px;
  padding: 0 20px 15px 20px;
}
.ui.card.power-hours-card.power-hours-summary .summary-info a {
  color: #F7902B !important;
}
.ui.card.power-hours-card.power-hours-summary .powerhourslogo {
  display: flex;
  align-items: center;
}
.ui.card.power-hours-card.power-hours-summary .summary-panels {
  display: flex;
  flex-wrap: wrap;
}
.ui.card.power-hours-card.power-hours-summary .summary-panels .summary-panel {
  width: 100%;
  text-align: center;
  margin: 20px 0 10px 0;
}
.ui.card.power-hours-card.power-hours-summary .summary-panels .summary-panel.border-top {
  border-top: 1px solid #f2f2f2;
}
.ui.card.power-hours-card.power-hours-summary .summary-panels .summary-panel.border-bottom {
  border-bottom: 1px solid #f2f2f2;
}
.ui.card.power-hours-card.power-hours-summary .summary-panels .summary-panel.left {
  color: #F7902B;
  width: 34%;
  border-right: 1px solid #f2f2f2;
}
.ui.card.power-hours-card.power-hours-summary .summary-panels .summary-panel.center {
  width: 28%;
  border-right: 1px solid #f2f2f2;
}
.ui.card.power-hours-card.power-hours-summary .summary-panels .summary-panel.right {
  width: 34%;
}
.ui.card.power-hours-card.power-hours-summary .summary-panels .summary-panel .panel-label {
  font-size: 16px;
  margin-bottom: 0.5rem;
}
.ui.card.power-hours-card.power-hours-summary .summary-panels .summary-panel .panel-value {
  font-size: 15px;
  line-height: 1.2;
}
.ui.card.power-hours-card.power-hours-summary .summary-panels .summary-panel .panel-value.huge {
  font-size: 30px;
  width: 80%;
  font-family: "graphik-medium";
  text-align: center;
  margin: auto;
}
@media (max-width: 400px) {
  .ui.card.power-hours-card.power-hours-summary .summary-panels .summary-panel .panel-value.huge {
    font-size: 23px;
  }
}

.edit-panel {
  padding: 10px;
  text-align: left;
  border-top: 1px solid #f2f2f2;
}

.edit-panel a {
  color: #F7902B !important;
}

.ui.card.power-hours-card {
  width: 100%;
  text-align: center;
}
.ui.card.power-hours-card:hover {
  transform: none;
  text-decoration: none;
  box-shadow: none;
}
.ui.card.power-hours-card .content {
  padding: 0;
}
.ui.card.power-hours-card > .invitation-content, .ui.card.power-hours-card .confirmed-content {
  padding: 1.5rem 0 0.5rem 0;
}
.ui.card.power-hours-card > .invitation-content .dropdown, .ui.card.power-hours-card .confirmed-content .dropdown {
  padding: 1.2rem 1rem;
}
.ui.card.power-hours-card > .invitation-content .dropdown .item, .ui.card.power-hours-card .confirmed-content .dropdown .item {
  line-height: 1.5;
}
@media only screen and (max-width: 767px) {
  .ui.card.power-hours-card > .invitation-content .ui.selection.dropdown .menu, .ui.card.power-hours-card .confirmed-content .ui.selection.dropdown .menu {
    max-height: 14.5rem;
  }
}
@media only screen and (min-width: 768px) {
  .ui.card.power-hours-card > .invitation-content .ui.selection.dropdown .menu, .ui.card.power-hours-card .confirmed-content .ui.selection.dropdown .menu {
    max-height: 14.5rem;
  }
}
.ui.card.power-hours-card > .invitation-content .button, .ui.card.power-hours-card .confirmed-content .button {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}
.ui.card.power-hours-card > .expired-content {
  padding: 1.5rem 1rem 2rem;
}
.ui.card.power-hours-card > .expired-content p {
  margin-bottom: 1.5rem;
}
.ui.card.power-hours-card .panels {
  display: flex;
  flex-flow: row wrap;
}
.ui.card.power-hours-card .panel {
  position: relative;
  width: 100%;
  padding: 1rem 0.5rem;
  text-align: center;
}
.ui.card.power-hours-card .panel.border-top {
  border-top: 1px solid #f2f2f2;
}
.ui.card.power-hours-card .panel.border-bottom {
  border-bottom: 1px solid #f2f2f2;
}
.ui.card.power-hours-card .panel.left {
  width: 50%;
  border-right: 1px solid #f2f2f2;
}
.ui.card.power-hours-card .panel.right {
  width: 50%;
}
.ui.card.power-hours-card .panel.hero {
  padding: 0.6rem 0.5rem;
}
.ui.card.power-hours-card .left-panel {
  border-right: 1px solid lightGrey;
}
.ui.card.power-hours-card .panel-label {
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 0.2em;
}
.ui.card.power-hours-card .panel-value {
  font-size: 15px;
  line-height: 1;
}
.ui.card.power-hours-card .panel-value.huge {
  font-size: 30px;
  width: 80%;
  font-family: graphik-medium;
  text-align: center;
  margin: auto;
}
.ui.card.power-hours-card .panel-value.large {
  font-size: 22px;
}
.ui.card.power-hours-card .panel-value.small {
  font-size: 15px;
}
.ui.card.power-hours-card .panel-value.pending {
  font-size: 30px;
  margin-top: 0.35em;
}
.ui.card.power-hours-card .panel-value.duration-left {
  font-size: 30px;
  margin-top: 0.5em;
}
.ui.card.power-hours-card .panel-value.address {
  font-weight: normal;
}
.ui.card.power-hours-card .panel-value .period {
  font-size: 0.48em;
}
.ui.card.power-hours-card .panel-value .date-for-time {
  font-size: 12px;
  line-height: 1.5;
}
.ui.card.power-hours-card .panel-value .dollars {
  font-size: 0.6em;
  position: relative;
  top: -0.6em;
  margin-right: 0.1em;
}
.ui.card.power-hours-card .panel-value .total-hours {
  font-size: 15px;
}
.ui.card.power-hours-card .info-panel {
  padding: 1rem 0.5rem;
}
.ui.card.power-hours-card .info-panel .panel-value + .panel-value {
  margin-top: 1rem;
}
.ui.card.power-hours-card .single-premise {
  margin: 18px;
  font-size: 15px;
  line-height: 1.2;
}
.ui.card.power-hours-card.hero {
  text-align: left;
}
.ui.card.power-hours-card.hero .event-name {
  font-weight: normal;
}
.ui.card.power-hours-card.hero .day {
  font-weight: 500;
}
.ui.card.power-hours-card.hero .time-columns {
  display: flex;
  align-items: flex-end;
  margin: 0.4rem 0 1.2rem;
}
.ui.card.power-hours-card.hero .time-columns > div {
  flex: 0 0 calc((100% - 42px) / 3);
  padding: 0;
  font-size: 12px;
}
.ui.card.power-hours-card.hero .time-columns > div.wide {
  flex: 0 0 calc((100% - 42px) * 0.6);
}
.ui.card.power-hours-card.hero .time-columns > div.wide:only-child {
  flex: 0 0 100%;
}
.ui.card.power-hours-card.hero .time-columns > div.wide + div {
  flex: 0 0 calc((100% - 42px) * 0.4);
}
.ui.card.power-hours-card.hero .time-columns > div:not(:first-child) {
  padding-left: 10px;
  margin-left: 10px;
}
.ui.card.power-hours-card.hero .time-columns .time {
  padding-top: 0px;
  margin-top: auto;
  font-size: 22px;
  font-family: "graphik-medium";
}
.ui.card.power-hours-card.hero .time-columns .time .period {
  font-size: 0.48em;
  line-height: 0;
}
.ui.card.power-hours-card.hero .time-columns .time.remaining {
  color: #F7902B;
}
.ui.card.power-hours-card.hero .time-columns .duration {
  padding-top: 0px;
  margin-top: auto;
  font-size: 22px;
  font-family: "graphik-medium";
}
@media (max-width: 400px) {
  .ui.card.power-hours-card.hero .time-columns {
    flex: 0 0 calc((100% - 22px) / 3);
  }
  .ui.card.power-hours-card.hero .time-columns.wide {
    flex: 0 0 calc((100% - 22px) * 2 / 3);
  }
  .ui.card.power-hours-card.hero .time-columns > div:not(:first-child) {
    padding-left: 5px;
    margin-left: 5px;
  }
  .ui.card.power-hours-card.hero .time-columns .time {
    font-size: 22px;
  }
}
.ui.card.power-hours-card svg {
  fill: #FFFFFF;
}

.solid-card-content.powerhours-hero-content {
  flex: 0 0;
}

.existingTimeSlot {
  font-family: "graphik-medium";
}

@media (max-width: 450px) {
  .ui.card.power-hours-card .panel-value.huge {
    font-size: 20px;
  }
}
.themed-dropdown {
  margin-top: 10px;
  background-color: var(--theme-dropdown-background-color);
  color: var(--theme-dropdown-color);
  border-color: var(--theme-dropdown-border-color);
}
.themed-dropdown .menu {
  background-color: var(--theme-dropdown-menu-background) !important;
  color: var(--theme-dropdown-menu-color) !important;
  border-color: var(--theme-dropdown-menu-border) !important;
  box-shadow: var(--theme-dropdown-menu-shadow) !important;
}
.themed-dropdown .menu .item {
  background-color: var(--theme-dropdown-menu-background) !important;
  color: var(--theme-dropdown-menu-color) !important;
}
.themed-dropdown .menu .item:hover {
  background-color: var(--theme-dropdown-menu-hover) !important;
}
.themed-dropdown .menu .item.selected {
  background-color: var(--theme-dropdown-menu-selected-background) !important;
  color: var(--theme-dropdown-menu-selected-color) !important;
}
.themed-dropdown .menu .item.disabled {
  background-color: var(--theme-dropdown-menu-disabled-background) !important;
  color: var(--theme-dropdown-menu-disabled-color) !important;
}


