.ui.layout-default.main-wrapper {
  padding-top: 3em;
  padding-bottom: 3em;
  margin-top: 0px;
}
.ui.layout-default.main-wrapper .main-container > .ui.header:first-child {
  font-weight: 500;
  margin-bottom: 1.5rem;
}
@media (max-width: 992px) {
  .ui.layout-default.main-wrapper {
    padding: 2em;
  }
  .ui.layout-default.main-wrapper .main-container > .ui.header:first-child {
    font-size: 1em;
    font-weight: bold;
    margin: 0;
    margin-top: -1em;
    padding: 0.7em 0.75em;
    border-bottom: 1px solid #dedede;
    margin-bottom: 1em;
    margin-left: -1em;
    margin-right: -1em;
  }
}

.ui.logged-out-layout.grid {
  margin-top: 0;
  margin-bottom: 0;
}
.ui.consent-form-layout .inputWithSelect .field.label {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.ui.consent-form-layout .inputWithSelect .ui.dropdown.label {
  font-size: 1.1em;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding-top: 0.6875em;
  padding-bottom: 0.6875em;
}

.smaller-field input {
  width: 17% !important;
}

.login-layout {
  min-height: 100vh;
  margin-bottom: -1rem;
  display: flex;
  flex-direction: column;
}


