$peak: #F7902B;
$off-peak: #0060AE;
$shoulder: #0060AE;

.time-of-use-graph {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	overflow: hidden;

	color: #0060AE;

	.peak {
		color: $peak;
	}
	.off-peak {
		color: $off-peak
	}
	
	header {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding: 10px 10px 12px;

		h2 {
			font-size: 23px;
			margin: 0;
			svg {
				margin-left: 0.5em;
				position: relative;
				top: 4px;
			}
		}
	}

	.time-of-use-labels {
		position: relative;
		height: 1em;
		margin-bottom: 15px;
	}
	.time-of-use-label {
		position: absolute;
		top: 0;
		transform: translateX(-50%);
	}

	.time-of-use-timeline {
		position: relative;
		height: 3px;
		div {
			position: absolute;
			top: 0;
			height: 3px;
			border-radius: 1.5px;
			&.peak {
				background: $peak;
			}
			&.off-peak {
				background: $off-peak;
			}
			&.shoulder {
				background: $shoulder;
			}
		}
	}

	.time-of-use-now {
		position: absolute;
		left: 50%;
		margin-top: 5px;
		margin-left: -9px;
		border-left: 9px solid transparent;
		border-right: 9px solid transparent;		
		border-bottom: 16px solid;
		&.peak {
			border-bottom-color: $peak;
		}
		&.off-peak {
			border-bottom-color: $off-peak;
		}
	}

}