.ui.basic.segment.account-picker-wrapper {
  padding: 0;
  margin: 1rem 0 1rem;
}

.ui.basic.selection.dropdown.account-picker {
  margin-top: 1rem;
}

@media only screen and (min-width: 480px) {
  .ui.basic.selection.dropdown.account-picker {
    margin: 1rem 0 0rem;
  }
}


