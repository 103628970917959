.weather-widget {
  font-size: 14px;
}
.weather-widget .weather-row {
  display: flex;
  padding-bottom: 5px;
}
.weather-widget svg {
  cursor: default;
  transform: scale(1.4, 1.4) translate(4.2px, 4.2px);
  margin: 0 3px;
}
.weather-widget .temperature {
  font-family: "graphik-medium", Arial, Helvetica, sans-serif;
  font-size: 25px;
  padding-left: 5px;
  margin: auto 3px 0 auto;
}

p.instalment__header {
  padding: 5px 0;
  margin: 0;
}

.main-container .home-loader {
  align-self: center;
  top: 30px;
}
@media (min-width: 992px) {
  .main-container .home-loader {
    top: 10px;
  }
}

.card-text-size {
  font-size: 35px;
}
.card-text-size .card-dollar {
  font-size: 24px;
}
.card-text-size .card-date {
  font-size: 24px;
}
.card-text-size .card-address {
  font-size: 16px;
  line-height: 1.6;
}
.card-text-size .card-life-support {
  font-size: 16px;
  line-height: 1.6;
}

@media (max-width: 991px) {
  .ui.inverted.menu .active.item {
    background: transparent;
  }
  .card-text-size {
    font-size: 30px;
  }
  .card-text-size .card-dollar {
    font-size: 20px;
  }
  .card-text-size .card-date {
    font-size: 20px;
  }
  .card-text-size .card-address {
    font-size: 12px;
    line-height: 1.4;
  }
  .card-text-size .card-life-support {
    font-size: 12px;
    line-height: 1.4;
  }
}
.ui.card > .header {
  position: relative;
}
@media (min-width: 992px) {
  .ui.card > .header {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .ui.card > .header.home-header-variable-height {
    padding-bottom: 0;
  }
}
@media (max-width: 370px) {
  .ui.card > .header.home-header-variable-height {
    padding-right: 23px;
  }
}

.ui.card .ui.button {
  border-radius: 4px;
  width: 100%;
}

.ui.cards > .card > .content {
  border: none;
}
@media (min-width: 992px) {
  .ui.cards > .card > .content {
    padding: 0;
  }
}
.ui.cards > .card > .content.no-padding {
  padding: 0;
}
@media (min-width: 992px) {
  .ui.cards > .card > .content.no-padding {
    padding: 0;
  }
}

.ui.cards > .child_card {
  width: calc(100% - 2em);
  margin: 12px;
}
@media (min-width: 768px) {
  .ui.cards > .child_card {
    margin: 12px;
    width: calc(50% - 2em);
  }
}

.ui.statistic > .value {
  font-size: 3.5rem !important;
}

.home-info-icon {
  color: grey;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 5px;
  height: 20px;
  width: 20px;
}
@media (min-width: 991px) {
  .home-info-icon {
    height: 24px;
    width: 24px;
  }
}

#home-total-bill-list {
  margin: 0;
  text-transform: none;
  font-size: 25px;
  padding: 1em;
}
@media (min-width: 768px) {
  #home-total-bill-list {
    padding: 15px 20px;
  }
}

#home-no-bill-list {
  margin: 0;
  text-transform: none;
  font-size: 0.36em;
  padding: 1em;
}
@media (min-width: 768px) {
  #home-no-bill-list {
    font-size: 0.45em;
    padding: 1.25em 1.6em;
  }
}
#home-no-bill-list.alternate {
  text-align: center;
  padding: 1em 0;
  font-size: 1.7em;
}

#time-of-use-indicator-text {
  font-size: 2.5em !important;
}

.no-card-paragraph {
  text-align: center;
  font-size: 1em;
  margin-bottom: 2em;
}
@media (min-width: 768px) {
  .no-card-paragraph {
    font-size: 1.2em;
  }
}

@media (min-width: 768px) {
  .account-picker-wrapper + div .no-card-paragraph:first-child {
    margin-top: -1.5rem;
  }
}
@media only screen and (max-width: 767px) {
  .ui.stackable.cards .ui.card.payment-plan:first-child {
    margin-top: 10px !important;
  }
}

.advancePayments {
  padding: 10px 0 15px 0;
}

.ui.card.payment-plan {
  text-align: center;
}
.ui.card.payment-plan .plan__header {
  font-size: 20px;
  padding: 0 0 20px 0;
  margin: 0;
}
.ui.card.payment-plan .plan__paynow-btn {
  width: 100%;
  border-radius: 5px;
  margin: 5% auto;
}
.ui.card.payment-plan .instalment__content {
  display: flex;
}
.ui.card.payment-plan .instalment__content > div {
  flex: 1 1 0;
  padding: 20px 10px;
}
.ui.card.payment-plan .instalment__text {
  font-size: 36px;
  line-height: 36px;
  margin: 0;
  height: 24px;
  font-family: "graphik-medium", Arial, Helvetica, sans-serif;
}
.ui.card.payment-plan .instalment__subheader {
  font-size: 12px;
  margin: 0;
}
.ui.card.payment-plan .upcomingInstalments__header {
  font-size: 12px;
  padding: 0px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ui.card.payment-plan .upcomingInstalments__header p {
  margin: 0;
}
.ui.card.payment-plan .upcomingInstalments__body {
  padding: 8px 0;
}
.ui.card.payment-plan .upcomingInstalments__list-item {
  padding: 4px 2px;
  display: flex;
  justify-content: space-between;
}
.ui.card.payment-plan .upcomingInstalments__list-item p {
  margin: 0;
}
.ui.card.payment-plan .load-more {
  margin-bottom: 12px;
  color: inherit;
  background: none;
  font-size: 12px;
}


