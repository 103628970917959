.mobile-navigation a.item.active svg {
  opacity: 1;
  color: #F78F28;
}
.mobile-navigation a.item:hover svg {
  opacity: 1;
  color: #F78F28;
}
.mobile-navigation a.item:active svg {
  opacity: 1;
  color: #F78F28;
}
.mobile-navigation a.item svg {
  opacity: 1;
}
.mobile-navigation a.item .count {
  align-self: flex-start;
  border-radius: 50%;
  background: #ed2f2f;
  width: 1rem;
  height: 1rem;
  font-size: 0.75em;
  color: white;
  text-align: center;
  padding: 0.125rem;
  margin-left: -1em;
}


