.ui.dimmer.page.page-noproduct {
  background: linear-gradient(221deg, #417dc4, #5fa7d4);
  bottom: 0 !important;
  top: inherit !important;

  .logout-button-container {
    @media(min-width: 992px) {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    @media(max-width: 992px) {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-bottom: 20px;
    }

    .logout-button {
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      color: white;
    }
  }

  .content {
    overflow: scroll;
    display: flex;
    justify-content: center;
    -webkit-overflow-scrolling: touch;
    z-index: 4;
    -ms-overflow-style: none; // IE 10+
    scrollbar-width: none; // Firefox
    &::-webkit-scrollbar {
      width: 0px; // *hide* scrollbar on mobile
      height: 0px;
      background-color: transparent;
    }

    .infographic-bottom-text {
      padding: 0 40px;
      font-size: 1.1em;
      margin: 10px auto;
      text-align: center;

      @media(min-width: 992px) {
        max-width: 800px;
        font-size: 1.4em;
      }
    }

    a {
      color: white;
      text-decoration: underline;
    }

    .header {
      font-weight: normal;
      margin: 0px;

      @media(min-width: 992px) {
        margin-bottom: -15px;
        font-size: 2em;
      }
    }

    .logo {
      margin: auto;
    }

    .infographic-image {
      max-width: 1300px;
      margin: 20px auto;

      @media (min-width: 992px) {
        width: 80vw;
      }

      @media (min-width: 650px) and (max-width: 991px) {
        width: 60vw;
      }

      @media (max-width: 649px) {
        width: 100%;
      }
    }

    .payment-button {
      border-radius: 8px;
      background: white;
      color: #0060ae;
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }

  .bottom-content {
    background-color: rgba(0,0,0,.3);
    padding: 20px;
    padding-bottom: 50px;
  }

  .description-text {
    padding: 0 10px;
    font-size: 1.1em;
    margin: 1em 0;

    @media(min-width: 992px) {
      font-size: 1.4em;
      margin-bottom: 1.4em;
      margin-top: 1em;

      p {
        margin-top: 0.5em;
      }
    }

    p {
      margin: 0px;
      margin-top: 1em;
    }
  }

  @media(min-width: 992px) {
    .ui.segments {
      width: 80%;
      margin: auto;
      padding: 30px;
    }

    .bottom-content {
      background-color: white;
      color: #1a1a1a;
    }
  }
}
