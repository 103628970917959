// Keyframes for the skeleton pulse animation
@keyframes pulse {
  0% {
    background-position: -150% 0;
  }
  100% {
    background-position: 150% 0;
  }
}

// Skeleton pulse animation class
.skeleton-pulse {
  animation: pulse 1.5s ease-in-out infinite;  // Normal speed for smooth looping
  background: linear-gradient(
    45deg, 
    rgba(90, 90, 90, 0.5) 25%,   // Darker grey for higher contrast
    rgba(200, 200, 200, 0.5) 50%,  // Lighter grey for higher contrast
    rgba(90, 90, 90, 0.5) 75%    // Return to darker grey
  );
  background-size: 200% 100%;  // Background size for smooth movement
  will-change: background-position;  // Optimize for better performance
  transform: translateZ(0);  // Ensure hardware acceleration
}

// Skeleton card styling
.skeleton-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  font-size: 14px;
  min-height: 160px;
  background-color: rgba(128, 128, 128, 0.5); // Default medium grey background
  opacity: 0.5;
  position: relative;
}
.card-group-with-margin {
  margin-top: 20px;
}