.signup-hr {
	margin: 10px -22.5px;
	border: none;
	border-top: 1px solid rgba(184, 181, 181, 0.5);	
	&.light {
		opacity: 0.5;
	}
	&.spaced {
		margin-top: 45px;
		margin-bottom: 45px;
	}
	&.space-above {
		margin-top: 45px;
	}
	&.space-below {
		margin-bottom: 45px;
	}
}

.signup-desktop-column {
	.signup-hr {
		margin-left: 0;
		margin-right: 0;
	}
}
