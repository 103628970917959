.predictabill .ui.one.cards > .card {
  margin-left: 0;
  margin-right: 0;
}
@media only screen and (max-width: 767px) {
  .predictabill .ui.stackable.one.cards > .card {
    margin-left: 0;
    margin-right: 0;
    width: 100% !important;
  }
}
.predictabill .styledText {
  font-size: 15px;
  line-height: 17px;
}
.predictabill .right-section {
  width: 50%;
  float: right;
  padding: 1.5em 0em;
}
.predictabill .left-section .ui.statistic > .value,
.predictabill .right-section .ui.statistic > .value {
  font-size: 3.2rem !important;
}

.ui.modal .actions {
  padding: 1rem 1rem 0rem;
}


