@keyframes pulse {
  0% {
    background-position: -150% 0;
  }
  100% {
    background-position: 150% 0;
  }
}
.skeleton-pulse {
  animation: pulse 1.5s ease-in-out infinite;
  background: linear-gradient(45deg, rgba(90, 90, 90, 0.5) 25%, rgba(200, 200, 200, 0.5) 50%, rgba(90, 90, 90, 0.5) 75%);
  background-size: 200% 100%;
  will-change: background-position;
  transform: translateZ(0);
}

.skeleton-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  font-size: 14px;
  min-height: 160px;
  background-color: rgba(128, 128, 128, 0.5);
  opacity: 0.5;
  position: relative;
}

.card-group-with-margin {
  margin-top: 20px;
}


