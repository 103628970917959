.ui.modal.splash-screen-modal {

	.splash-screen-modal-icon {
		width: 70px;
		margin: 1em auto 1em;
		line-height: 0;
		text-align: center;
	}

	h2 {
		text-align: center;		
		margin: 0 auto 0.6em;
	}

	&.ui.modal {
		.description {
			padding: 1rem 0;
		}
		.actions {
			padding-bottom: 0;
		}
	}

	p {
		margin-bottom: 0.5em;
	}

	&.ui.modal > .actions {
		text-align: center;
	}

	.splash-screen-remind-me {
		display: inline-block;
		margin-bottom: 1em;
		text-decoration: none;
	}

	.splash-screen-viewed-count {
		text-align: center;
		font-size: 0.8em;
		margin-bottom: 1.5em;
	}

	&.template-orange {
		
		border-radius: 9px;
		& > .icon:first-child+*,
		& > :first-child:not(.icon) {
			border-top-left-radius: 9px;
			border-top-right-radius: 9px;
		}

		background: #F7902B;
		& > .content,
		& > .actions {
			background: #F7902B;
		}

		h2, p, div, a {
			color: white;
		}

		.description {
			a {
				text-decoration: underline;
			}
		}

		.button {
			border-radius: 23px;
			border: 1px solid white;
			font-weight: 500;
		}

		.splash-screen-remind-me {
			margin: 1rem auto 2rem;
		}
	}
}