.password-validation-info {
  padding-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 20px;
  text-align: left;
  border-bottom: solid rgba(0, 0, 0, 0.08) 1px;
}
.password-validation-info i {
  float: right;
  color: #999999;
}
.password-validation-info i.checked {
  color: green;
}
.password-validation-info.white ul li:before {
  color: white;
}
.password-validation-info.white i {
  color: rgba(255, 255, 255, 0.2);
}


