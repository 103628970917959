@charset "UTF-8";
@media (min-width: 992px) {
  .experience-settings-form {
    max-width: 720px;
    margin: 0 auto;
  }
  .experience-settings-form .carousel-segment {
    padding: 10px 10px 5px;
  }
}
.experience-settings-form .carousel-segment {
  background: white;
  border-radius: 4px;
  margin: 1em 0px 2em;
  padding: 5px 5px 0px;
}
.experience-settings-form.wrapper {
  position: relative;
  top: 5vh;
  padding-bottom: 4em;
}
.experience-settings-form .header {
  color: white;
  font-weight: normal;
  text-align: center;
  font-size: 25px;
}
.experience-settings-form .button.cancel-btn, .experience-settings-form .button.cancel-btn:hover {
  margin: 10px;
  color: white !important;
  background: none !important;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.35) inset, 0 0 0 0 rgba(255, 255, 255, 0.15) inset;
  width: 75%;
}
@media (min-width: 992px) {
  .experience-settings-form .button.cancel-btn, .experience-settings-form .button.cancel-btn:hover {
    width: 40%;
  }
}
.experience-settings-form .submit-btn {
  margin: 10px;
  width: 75%;
}
@media (min-width: 992px) {
  .experience-settings-form .submit-btn {
    width: 40%;
  }
}
.experience-settings-form .button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.experience-carousel .ui.centered.image {
  border-radius: 4px;
}
.experience-carousel .slick-dots {
  bottom: -35px;
}
.experience-carousel .slick-dots li button:before {
  color: white;
  content: "○";
  font-size: 25px;
}
.experience-carousel .slick-dots li.slick-active button:before {
  color: white;
  content: "●";
}


