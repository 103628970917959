
.predictabill {
  .ui.one.cards > .card {
    margin-left: 0;
    margin-right: 0;
  }

  @media only screen and (max-width: 767px) {

    .ui.stackable.one.cards > .card {
      margin-left: 0;
      margin-right: 0;
      width: 100% !important;
    }
  }

  .styledText {
    font-size: 15px;
    line-height: 17px;
  }

  .right-section {
    width: 50%;
    float: right;
    padding: 1.5em 0em;
  }

  .left-section,
  .right-section {
    .ui.statistic > .value {
      font-size: 3.2rem !important;
    }
  }
}
.ui.modal .actions {
  padding: 1rem 1rem 0rem;
}