.deeplink-launch-page {

	$blue: #0060AE;

	.launch-box {
		text-align: center;

		padding: 2rem 1.5rem 2.5rem;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@media(max-width: 767px) {
			min-height: 100%;
			background: white;
		}

		@media(min-width: 768px) {
			width: 420px;
			margin: auto;
			background: rgba(255, 255, 255, 0.88);

			border-radius: 12px;
			box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);			
		}


	}

	h1 {
		color: $blue;
		max-width: 10em;
		margin: 0 auto 2.5rem;
	}

	.deeplink-graphic {
		margin: 0 auto 2rem;
	}

	.launch-button {
		padding: 18px;
		margin: 0 0 2rem;
	}

	.ui.fluid.button.continue-in-browser {
		padding: 18px;
		box-shadow: 0px 0px 0px 2px $blue inset !important;
		color: $blue;
	  	margin: 0 0 5rem;
	}


	.learn-more {
		font-size: 18px;
		line-height: 1.6;
		color: $blue;
		a {
			display: block;
			text-decoration: underline;
		}
	}

	.logo {
		margin-top: 2rem;
		display: flex;

		img.ui.image {
			width: 200px;
			margin: 0 auto 0;
		}
	}


	
}
