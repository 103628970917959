.concession-form-container {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  
  .button-container {
    text-align: center;
    
    .back-button {
      width: 100%;
      max-width: 400px;
    }
  }

  .concession-form-wrapper {
    width: 100%;
    display: block;
    overflow: hidden;

    .concession-form-frame {
      width: 100%;
      height: 100%;
      border: none;
      display: block;
      overflow: hidden;
    }
  }
} 