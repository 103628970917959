.signup-faq {
  margin-top: 50px;
  font-size: 13px;
}
.signup-faq h3 {
  font-weight: 400;
  color: #4a4a4a;
  margin-bottom: 30px;
}
.signup-faq .accordion.ui {
  border-bottom: 1px solid rgba(184, 181, 181, 0.5);
}
.signup-faq .accordion.ui .title {
  font-size: inherit;
  position: relative;
  border-top: 1px solid rgba(184, 181, 181, 0.5);
  padding: 20px 45px 20px 0;
}
.signup-faq .accordion.ui .title .icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform-origin: 50% 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}
.signup-faq .accordion.ui .title .icon:before {
  content: "";
  display: block;
  position: absolute;
  width: 1em;
  height: 1em;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  left: 50%;
  top: 50%;
  transform-origin: 50% 50%;
  transform: translate(-50%, -75%) rotate(45deg);
}
.signup-faq .accordion.ui .title.active .icon {
  transform: translate(-50%, -50%) rotate(180deg);
}
.signup-faq .accordion.ui .content {
  font-size: inherit;
  margin: -5px 45px 15px 15px;
}
.signup-faq .accordion.ui p {
  font-size: inherit;
}


