.ui.SolarSystemSizeGrid {
    margin-bottom: 0rem;

    @media (max-width: 991px) {
        margin-bottom: initial;
    }

    .row {
        padding: .5rem 0rem;
        &:nth-child(odd) {
            background: var(--alternate-background) !important;
        }
    }    

    .SystemSize,
    .SystemKwhSize {
        font-size: .75rem;

        @media (max-width: 991px) {
            font-size: .75rem;
        }
    }
}

.SolarSystemTableDescription {
    font-size: .75rem;
    font-weight: bold;

    @media (max-width: 991px) {
        font-size: .5rem;
    }
}

.SolarSystemSizeConditions {
  margin-top: 3rem;

  @media (max-width: 991px) {
    padding: 0rem .5rem;
    margin-top: 0;
  }

  .SolarSystemSizeConditionsText {
    font-size: .75rem;
  }
}

.ui.header.SolarSystemSizeHeader{
    font-size: 1.275rem;
    font-weight: normal;

    @media (max-width: 991px) {
        font-size: 1.1rem;
    }
}

