.signup-estimation {
	background: #DEECF8;
	padding: 2em 1.5em 1.5em;
	border-radius: 14px;
	text-align: center;
	margin: 10px 0 30px;

	.signup-estimation-progress-bar {
		position: relative;
		width: 100%;
		background: white;
		height: 20px;
		border-radius: 6px;
		margin-bottom: 1.5em;
		div {
			background: #0060AE;
			height: 100%;
			border-radius: 6px;
		}
	}

	.signup-estimation-date {
		color: #0060AE;
		font-size: 1.8em;
		margin-bottom: 0;
	}

}