.ui.dimmer.page.page-noproduct {
  background: linear-gradient(221deg, #417dc4, #5fa7d4);
  bottom: 0 !important;
  top: inherit !important;
}
@media (min-width: 992px) {
  .ui.dimmer.page.page-noproduct .logout-button-container {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
@media (max-width: 992px) {
  .ui.dimmer.page.page-noproduct .logout-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 20px;
  }
}
.ui.dimmer.page.page-noproduct .logout-button-container .logout-button {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: white;
}
.ui.dimmer.page.page-noproduct .content {
  overflow: scroll;
  display: flex;
  justify-content: center;
  -webkit-overflow-scrolling: touch;
  z-index: 4;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.ui.dimmer.page.page-noproduct .content::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: transparent;
}
.ui.dimmer.page.page-noproduct .content .infographic-bottom-text {
  padding: 0 40px;
  font-size: 1.1em;
  margin: 10px auto;
  text-align: center;
}
@media (min-width: 992px) {
  .ui.dimmer.page.page-noproduct .content .infographic-bottom-text {
    max-width: 800px;
    font-size: 1.4em;
  }
}
.ui.dimmer.page.page-noproduct .content a {
  color: white;
  text-decoration: underline;
}
.ui.dimmer.page.page-noproduct .content .header {
  font-weight: normal;
  margin: 0px;
}
@media (min-width: 992px) {
  .ui.dimmer.page.page-noproduct .content .header {
    margin-bottom: -15px;
    font-size: 2em;
  }
}
.ui.dimmer.page.page-noproduct .content .logo {
  margin: auto;
}
.ui.dimmer.page.page-noproduct .content .infographic-image {
  max-width: 1300px;
  margin: 20px auto;
}
@media (min-width: 992px) {
  .ui.dimmer.page.page-noproduct .content .infographic-image {
    width: 80vw;
  }
}
@media (min-width: 650px) and (max-width: 991px) {
  .ui.dimmer.page.page-noproduct .content .infographic-image {
    width: 60vw;
  }
}
@media (max-width: 649px) {
  .ui.dimmer.page.page-noproduct .content .infographic-image {
    width: 100%;
  }
}
.ui.dimmer.page.page-noproduct .content .payment-button {
  border-radius: 8px;
  background: white;
  color: #0060ae;
  margin-top: 20px;
  margin-bottom: 40px;
}
.ui.dimmer.page.page-noproduct .bottom-content {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 20px;
  padding-bottom: 50px;
}
.ui.dimmer.page.page-noproduct .description-text {
  padding: 0 10px;
  font-size: 1.1em;
  margin: 1em 0;
}
@media (min-width: 992px) {
  .ui.dimmer.page.page-noproduct .description-text {
    font-size: 1.4em;
    margin-bottom: 1.4em;
    margin-top: 1em;
  }
  .ui.dimmer.page.page-noproduct .description-text p {
    margin-top: 0.5em;
  }
}
.ui.dimmer.page.page-noproduct .description-text p {
  margin: 0px;
  margin-top: 1em;
}
@media (min-width: 992px) {
  .ui.dimmer.page.page-noproduct .ui.segments {
    width: 80%;
    margin: auto;
    padding: 30px;
  }
  .ui.dimmer.page.page-noproduct .bottom-content {
    background-color: white;
    color: #1a1a1a;
  }
}


