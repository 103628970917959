@keyframes bounce {
  from, 20%, 53%, 80%, to {
    transform: translateX(0%);
  }
  40%, 43% {
    transform: translateX(-5%);
  }
  65% {
    transform: translateX(-3%);
  }
  90% {
    transform: translateX(-1%);
  }
}
.grouped-bar-chart {
  position: relative;
}
.grouped-bar-chart svg {
  fill: transparent;
  pointer-events: all;
}
.grouped-bar-chart svg .more-data-right-arrow {
  opacity: 0;
  transform: translate(90%, 23%);
}
@media (max-width: 767px) {
  .grouped-bar-chart svg .more-data-right-arrow {
    transform: translate(80%, 23%);
  }
}
.grouped-bar-chart svg .more-data-right-arrow.showing {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}
.grouped-bar-chart svg .more-data-right-arrow.showing .animated-transform {
  animation: bounce 1000ms 0.5s ease;
}
.grouped-bar-chart svg .more-data-right-arrow path {
  stroke: rgba(0, 0, 0, 0.19);
  stroke-width: 1.5px;
}
.grouped-bar-chart svg text {
  font-weight: 400;
}
.grouped-bar-chart .axis {
  fill: none;
  font-size: 10px;
  font-family: sans-serif;
}
.grouped-bar-chart .axis path {
  display: none;
}
.grouped-bar-chart .axis .tick {
  opacity: 1;
}
.grouped-bar-chart .axis .tick line {
  stroke: transparent;
  stroke-width: 0;
}
.grouped-bar-chart .axis .tick text {
  font-weight: 400;
  user-select: none;
}
.grouped-bar-chart .axis.x-axis {
  text-anchor: middle;
  cursor: pointer;
}
.grouped-bar-chart .axis.x-axis .domain {
  display: none;
}
.grouped-bar-chart .axis.x-axis text {
  font-size: 12px;
}
.grouped-bar-chart .axis.y-axis {
  text-anchor: end;
}
.grouped-bar-chart .axis.y-axis .axis-label {
  font-size: 12px;
  text-anchor: middle;
}
.grouped-bar-chart .axis.y-axis .odd line {
  stroke: var(--alternate-background) !important;
}
.grouped-bar-chart .bar-group {
  cursor: pointer;
}
.grouped-bar-chart .errorMessage {
  text-align: center;
  position: absolute;
  font-size: 0.8rem;
  padding: 0 5em;
  transform: translateY(50%);
  width: 100%;
  user-select: none;
}
@media (max-width: 991px) {
  .grouped-bar-chart .errorMessage {
    font-size: 0.75rem;
  }
}
.grouped-bar-chart .warningBox {
  text-align: center;
  position: absolute;
  font-size: 0.8rem;
  padding: 2em 3em;
  transform: translate(-50%, 50%);
  width: 90%;
  left: 50%;
  user-select: none;
  background: rgba(255, 255, 255, 0.95);
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, 0.2), 1px 3px 15px 2px rgba(0, 0, 0, 0.2);
}
@media (max-width: 991px) {
  .grouped-bar-chart .warningBox {
    font-size: 0.75rem;
  }
}
.grouped-bar-chart .warningBox > *:nth-child(n-2) {
  margin-bottom: 0;
}
.grouped-bar-chart .warningBox-close-icon {
  position: absolute;
  right: 8px;
  top: 8px;
  color: rgba(0, 0, 0, 0.45);
}


