.select-plan {
	margin: 4rem 0;
}

.plan-wrapper {
	position: relative;
	width: 100%;
}

.plan-container {
	display: flex;
	gap: 1.5rem;
	//width: 200%;
	width: 180%; // Get more of the second plan onto the screen
	padding: 0 1rem;
	transform: translateX(0%);
	transition: transform 150ms ease-in;
}

.plan-container.transition {
	//transform: translateX(-50%);
	transform: translateX(-44.4%); // Need to adjust for 180% instead of 200% above
}

.plan-container {
	max-width: 760px;
}

.plan-outer {
	margin-top: 20px;
	flex: 1;
}

.plan-current {
	margin-bottom: -.5rem;
	.active {
		display: inline-block;
		color: white;
		background-color: #f7902b;
		border-top-left-radius: .5rem;
		border-top-right-radius: .5rem;
		padding: 1rem 2.25rem 1.25rem 1rem;
		font-size: 1.4rem;
	}
}

.plan {
	position: relative;
	border-radius: 0.59375rem;
	box-shadow: 0.794182px 0.794182px 4.76509px 0.794182px rgba(0, 0, 0, .25);
	overflow: hidden;
	margin-bottom: 1.5rem;
	font-size: 1rem;
	line-height: 1.4285em;

	header {
		display: flex;
		flex-wrap: wrap;
		//align-items: baseline;
		justify-content: space-between;
		color: #fefefe;
		background: rgb(6, 72, 125);
		padding: 1.5rem 1.125rem 0.5rem;
		
		h4 {
			font-size: 1.4rem;
			line-height: 1;
			margin-bottom: 0.4rem;
		}

		p {
			line-height: 1;
			margin-top: auto;
			margin-left: auto;
			margin-bottom: 0.4rem;
			font-weight: 300;
		}
	}

}

.plan-section {
	padding: 1.5rem 1.125rem;

	h5 {
		font-weight: 400;
		font-size: 1.2rem;
		margin-bottom: 1.5rem;
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	li {
		display: flex;
		gap: .875rem;
		color: #676767;
		margin-bottom: 1rem;
	}

	li:last-child {
		margin-bottom: 0;
	}
}

.plan-link {
	display: flex;
	justify-content: center;
	padding-top: 1rem;
}

.plan hr {
	position: absolute;
	width: 100%;
	left: -.05rem;
	height: .125rem;
	border-top: .05rem solid #989898;
	border-bottom: .05rem solid #989898;
	margin: -.125rem 0 0;
}

.ui.button.plan-button {
	background-color: white;
	border: 0.0875rem solid rgba(0, 0, 0, 0.6);

	&:hover {
		border: 0.0875rem solid rgba(0, 0, 0, 1);
	}

	&.active {
		background: #E9F5FF;
		border-color: #0060AE;
		color: #0060AE;
	}
}