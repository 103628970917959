.icon-radio.ui,
.icon-checkbox.ui {
  display: block;
  height: 100%;
  text-align: center;
}
.icon-radio.ui input,
.icon-checkbox.ui input {
  display: none;
}
.icon-radio.ui label,
.icon-checkbox.ui label {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: rgba(0, 0, 0, 0.87);
  border: 2px solid #A09B9B;
  border-radius: 0.5rem;
  padding: 16px 10px 16px;
  cursor: pointer;
}
.icon-radio.ui label:before, .icon-radio.ui label:after,
.icon-checkbox.ui label:before,
.icon-checkbox.ui label:after {
  content: none;
}
.icon-radio input:checked + label,
.icon-checkbox input:checked + label {
  background: #E9F5FF;
  border-color: #0060AE;
  color: #0060AE;
}
.icon-radio-icon,
.icon-checkbox-icon {
  margin-top: 10px;
  margin-bottom: 0;
  line-height: 0;
}
.icon-radio-label,
.icon-checkbox-label {
  margin-top: auto;
  line-height: 0;
  min-height: 44px;
  display: flex;
}
.icon-radio-label span,
.icon-checkbox-label span {
  margin: auto;
  font-size: 15px;
  line-height: 22px;
}

.ui.form .icon-radio-group,
.ui.form .icon-checkbox-group {
  margin-bottom: 0.5em;
  max-width: 500px;
  margin: 0 auto 0.5em;
}
.ui.form .icon-radio-group .fields,
.ui.form .icon-checkbox-group .fields {
  display: flex;
  flex-flow: row wrap;
  margin: -0.5em -0.5em 0;
  justify-content: center;
}
.ui.form .icon-radio-group .fields > .field,
.ui.form .icon-checkbox-group .fields > .field {
  width: 50%;
  max-width: 208.5px;
  padding: 0.5em;
}
.ui.form .icon-radio-group.wide .fields,
.ui.form .icon-checkbox-group.wide .fields {
  flex-flow: column;
}
.ui.form .icon-radio-group.wide .fields > .field,
.ui.form .icon-checkbox-group.wide .fields > .field {
  width: 100%;
  max-width: 417px;
}
.ui.form .icon-radio-group.wide .icon-radio.ui label,
.ui.form .icon-radio-group.wide .icon-checkbox.ui label,
.ui.form .icon-checkbox-group.wide .icon-radio.ui label,
.ui.form .icon-checkbox-group.wide .icon-checkbox.ui label {
  flex-direction: row;
  align-items: center;
  padding: 16px 16px;
}
.ui.form .icon-radio-group.wide .icon-radio-label,
.ui.form .icon-radio-group.wide .icon-checkbox-label,
.ui.form .icon-checkbox-group.wide .icon-radio-label,
.ui.form .icon-checkbox-group.wide .icon-checkbox-label {
  margin: auto 20px auto 0;
  text-align: left;
}
.ui.form .icon-radio-group.wide .icon-radio-icon,
.ui.form .icon-radio-group.wide .icon-checkbox-icon,
.ui.form .icon-checkbox-group.wide .icon-radio-icon,
.ui.form .icon-checkbox-group.wide .icon-checkbox-icon {
  order: 999;
  margin: 0 0 0 auto;
}
.ui.form .icon-radio-group legend,
.ui.form .icon-checkbox-group legend {
  display: block;
  margin: 5px 0 -5px;
}


