.ui.dimmer.page.page-samlsso {
  background-color: #0060ae;

  position: absolute;
  bottom: 0 !important;
  top: inherit !important;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
      width: 150px;
    }

    .text {
      margin-top: 1em;
      margin-bottom: 1em;
      white-space: pre-line;
    }
  }
}
