
.ui.basic.selection.dropdown.usage-dropdown {
	@media(max-width: 767px) {
		margin-top: 1rem;
	}
	@media(min-width: 768px) {
		width: 10em;
	}
}

.ui.header.usage-header {
	margin: 0;
	font-size: 16px;
	font-weight: normal;
}
  


// ------------ Usage      ---------------------------------------

.usage-summary-table.ui.table {
	
	tr td {
		border: none;
		font-weight: 400;
		color:inherit!important
	}
}

.usage-graph-header.ui.header {
	@media (max-width: 991px) {
		font-size: 1em;
	}
}


.chart-legends {
	display: flex;
	justify-content: center;

	> .label {
		background-color: transparent;
		padding: .5rem .25rem;
	}

	> .ui.label {
		color: inherit;

		> .detail {
			margin-left: 0.5em;
			font-weight: 400;

			> img {
				margin-top: -4px;
				width: 22px;
			}

			@media (max-width: 460px) {
				font-size: .75rem;
				line-height: 1rem;

				> svg {
				width: 1rem;
				}
			}
		}
	}
	
}