.request-deletion {
	section {
		margin-bottom: 1em;

		ul {
			margin: 0;
			padding-inline-start: 1.7em;

			ul {
				list-style-type: disc;
			}
		}

		&:last-of-type {
			margin-bottom: 3em;
		}
	}

	.error-message {
		color: red;
	}
}

