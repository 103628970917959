.ui.checkbox.signup-checkbox {
  margin: 0 0 1em;
}
.ui.checkbox.signup-checkbox label {
  padding-left: 38px;
  display: flex;
  min-height: 38px;
  font-size: 15px;
}
.ui.checkbox.signup-checkbox label .signup-checkbox-label-inner {
  margin: auto 0;
}
.ui.checkbox.signup-checkbox label:before {
  top: 5px;
  border-radius: 5px;
  border-color: #A09B9B;
  width: 28px;
  height: 28px;
}
.ui.checkbox.signup-checkbox label:after {
  top: 5px;
  font-size: 1.1em;
  line-height: 28px;
  width: 28px;
  height: 28px;
}
.ui.checkbox.signup-checkbox.small label {
  padding-left: 43px;
  font-size: 0.8666em;
  line-height: 2.1;
}


