.property-display {
  display: flex;
  padding: 12px 15px;
  font-size: 17px;
}
.property-display .property-display-address {
  margin-left: 1em;
  margin-right: 1em;
  font-size: 0.95em;
  line-height: 1.4;
}
.property-display .property-display-status {
  position: relative;
  margin-left: auto;
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.7em;
  line-height: 1.2;
}
.property-display .property-display-status span {
  display: block;
  margin-top: 0.3em;
}
.property-display .expander.icon {
  position: absolute;
  right: 0px;
  top: 50%;
  transform-origin: 50% 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-size: 15px;
}
.property-display .expander.icon:before {
  content: "";
  display: block;
  position: absolute;
  width: 1em;
  height: 1em;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  left: 50%;
  top: 50%;
  transform-origin: 50% 50%;
  transform: translate(-50%, -75%) rotate(45deg);
}

.accordion.ui .title.active .property-display .expander.icon {
  transform: translate(-50%, -50%) rotate(180deg);
}

.property-radio input {
  display: none;
}
.property-radio label {
  display: block;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.5rem;
  cursor: pointer;
}
.property-radio label .property-display {
  margin: -1px;
}
.property-radio input:checked + label {
  background: #E9F5FF;
  border-color: #0060AE;
}

.ui.form .property-radio-group .fields {
  display: flex;
  flex-flow: column;
  margin: -1em 0;
}
.ui.form .property-radio-group .fields > .field {
  padding: 1em 0;
}

.property-link {
  display: block;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.5rem;
  margin-bottom: 1em;
  color: inherit;
}
.property-link:hover {
  color: inherit;
  text-decoration: none;
}


