.confirmfooter {
	font-size: 10px;
}

.ui.card.power-hours-card.power-hours-summary {
    margin: 20px 0 10px 0;

    .summary-header {
        width: 100%;
        display: flex; // Enable flexbox for the header
        align-items: center; // Vertically align items in the center
        justify-content: space-between; // Space items between (title on the left, logo on the right)
		height: 30px;
		padding: 22px 20px 20px 15px;
		font-family: graphik-medium;
		font-size: 14px;
		line-height: 1.1em;
		text-align: left;
    }

    .summary-header svg {
        width: 150px;
    }

    .summary-title {
       
    }
	.summary-info{
		font-size: 13px;
		padding: 0 20px 15px 20px;
	}

	.summary-info a{
		color: #F7902B!important;

	   }


    .powerhourslogo {
        display: flex; // Ensure logo is properly aligned
        align-items: center; // Vertically center the logo
    }

    .summary-panels {
        display: flex;
        flex-wrap: wrap;

        .summary-panel {
            width: 100%;
            text-align: center;
			margin: 20px 0 10px 0;

            &.border-top {
                border-top: 1px solid #f2f2f2;
            }
            &.border-bottom {
                border-bottom: 1px solid #f2f2f2;
            }
            &.left {
                color: #F7902B;
                width: 34%;
                border-right: 1px solid #f2f2f2;
            }
            &.center {
                width: 28%;
                border-right: 1px solid #f2f2f2;
            }
            &.right {
                width: 34%;
            }

            .panel-label {
                font-size: 16px;
                margin-bottom: 0.5rem;
            }

            .panel-value {
				font-size: 15px;
				line-height: 1.2;
				&.huge {
					font-size: 30px;
					width: 80%;
					font-family: "graphik-medium";
					text-align: center;
					margin: auto;
		
					@media (max-width: 400px) {
						font-size: 23px; // Smaller font size for mobile
					}
				}
			}
		}
	}
}

.edit-panel{
padding:10px;
text-align:left;
border-top: 1px solid #f2f2f2;
}

.edit-panel a{
color: #F7902B!important;
}

.ui.card.power-hours-card {
    width: 100%;
    text-align: center;

    &:hover {
        transform: none;
        text-decoration: none;
        box-shadow: none;
    }

    .content {
        padding: 0;
    }

    & > .invitation-content, .confirmed-content {
        padding: 1.5rem 0 0.5rem 0;

        .dropdown {
            padding: 1.2rem 1rem;
            .item {
                line-height: 1.5;
            }
        }

        .ui.selection.dropdown .menu {
            @media only screen and (max-width: 767px) {
                max-height: 14.5rem;
            }
            @media only screen and (min-width: 768px) {
                max-height: 14.5rem;
            }
        }

        .button {
            margin-left: auto;
            margin-right: auto;
            margin-top: 1rem;
        }
    }

    & > .expired-content {
        padding: 1.5rem 1rem 2rem;
        p {
            margin-bottom: 1.5rem;
        }
    }

    .panels {
        display: flex;
        flex-flow: row wrap;
    }

    .panel {
        position: relative;
        width: 100%;
        padding: 1rem 0.5rem;
        text-align: center;

        &.border-top {
            border-top: 1px solid #f2f2f2;
        }
        &.border-bottom {
            border-bottom: 1px solid #f2f2f2;
        }

        &.left {
            width: 50%;
            border-right: 1px solid #f2f2f2;
        }
        &.right {
            width: 50%;
        }

        &.hero {
            padding: 0.6rem 0.5rem;
        }
    }

    .wide-panel {}
    .left-panel {
        border-right: 1px solid lightGrey;
    }
    .right-panel {}

    .panel-label {
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 0.2em;
    }

    .panel-value {
        font-size: 15px;
        line-height: 1.0;
        &.huge {
        font-size: 30px;
		width: 80%;
		font-family: graphik-medium;
		text-align: center;
		margin: auto;
        }
        &.large {
            font-size: 22px;
        }
        &.small {
            font-size: 15px;
        }
        &.pending {
            font-size: 30px;
            margin-top: 0.35em;
        }
        &.duration-left {
            font-size: 30px;
            margin-top: 0.5em;
        }
        &.address {
            font-weight: normal;
		}
        .period {
            font-size: 0.48em;
        }
        .date-for-time {
            font-size: 12px;
            line-height: 1.5;
        }
        .dollars {
            font-size: 0.6em;
            position: relative;
            top: -0.6em;
            margin-right: 0.1em;
        }
        .total-hours {
            font-size: 15px;
        }
    }

    .info-panel {
        padding: 1rem 0.5rem;
        .panel-value + .panel-value {
            margin-top: 1rem;
        }
    }

    .single-premise {
        margin: 18px;
        font-size: 15px;
        line-height: 1.2;
    }

    &.hero {
        text-align: left;

        .event-name {
            font-weight: normal;
        }
        .day {
            font-weight: 500;
        }

        .time-columns {
            display: flex;
            align-items: flex-end;
            margin: 0.4rem 0 1.2rem;

            & > div {
                flex: 0 0 calc((100% - 42px) / 3);
                padding: 0;
                font-size: 12px;

                &.wide {
                    flex: 0 0 calc((100% - 42px) * 0.6);

                    &:only-child {
                        flex: 0 0 100%;
                    }
                }

                &.wide + div {
                    flex: 0 0 calc((100% - 42px) * 0.4);
                }

                &:not(:first-child) {
                    padding-left: 10px;
                    margin-left: 10px;
                }
            }

            .time {
                padding-top: 0px;
                margin-top: auto;
                font-size: 22px;
                font-family: 'graphik-medium';

                .period {
                    font-size: 0.48em;
                    line-height: 0;
                }

                &.remaining {
                    color: #F7902B;
                }
            }

            .duration {
                padding-top: 0px;
                margin-top: auto;
                font-size: 22px;
                font-family: 'graphik-medium';
            }
        }

        @media(max-width: 400px) {
            .time-columns {
                flex: 0 0 calc((100% - 22px) / 3);

                &.wide {
                    flex: 0 0 calc((100% - 22px) * 2 / 3);
                }

                & > div {
                    &:not(:first-child) {
                        padding-left: 5px;
                        margin-left: 5px;
                    }
                }

                .time {
                    font-size: 22px;
                }
            }
        }
    }

    svg {
        fill: #FFFFFF;
    }
}

.solid-card-content.powerhours-hero-content {
    flex: 0 0;
}

.existingTimeSlot {
	font-family: 'graphik-medium';
}

@media (max-width: 450px) {
    .ui.card.power-hours-card .panel-value.huge {
        font-size: 20px;
    }
}

.themed-dropdown {
    margin-top: 10px;
    background-color: var(--theme-dropdown-background-color);
    color: var(--theme-dropdown-color);
    border-color: var(--theme-dropdown-border-color);

    .menu {
        background-color: var(--theme-dropdown-menu-background) !important;
        color: var(--theme-dropdown-menu-color) !important;
        border-color: var(--theme-dropdown-menu-border) !important;
        box-shadow: var(--theme-dropdown-menu-shadow) !important;

        .item {
            background-color: var(--theme-dropdown-menu-background) !important;
            color: var(--theme-dropdown-menu-color) !important;

            &:hover {
                background-color: var(--theme-dropdown-menu-hover) !important;
            }

            &.selected {
                background-color: var(--theme-dropdown-menu-selected-background) !important;
                color: var(--theme-dropdown-menu-selected-color) !important;
            }

            &.disabled {
                background-color: var(--theme-dropdown-menu-disabled-background) !important;
                color: var(--theme-dropdown-menu-disabled-color) !important;
            }
        }
    }
}