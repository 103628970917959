.ui.dimmer.page.page-samlsso {
  background-color: #0060ae;
  position: absolute;
  bottom: 0 !important;
  top: inherit !important;
}
.ui.dimmer.page.page-samlsso .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ui.dimmer.page.page-samlsso .content .logo {
  width: 150px;
}
.ui.dimmer.page.page-samlsso .content .text {
  margin-top: 1em;
  margin-bottom: 1em;
  white-space: pre-line;
}


