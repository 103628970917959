.iframe-container {
	position: relative;
	width: 100%;
	flex: 1 1 -0em;

	iframe {
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		min-width: 100%;
		top: 0;
		bottom: 0;
		height: 100%;
		min-height: 100%;

		border: 0;
		outline: 0;
		background: none;
	}
}