

@font-face {
    font-family: 'graphik';
    src: url('./graphikregular-webfont.eot');
    src: url('./graphikregular-webfont.eot?#iefix') format('embedded-opentype'),
         url('./graphikregular-webfont.woff2') format('woff2'),
         url('./graphikregular-webfont.woff') format('woff'),
         url('./graphikregular-webfont.ttf') format('truetype'),
         url('./graphikregular-webfont.svg#graphik_regularregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'graphik-medium';
    src: url('./graphikmedium-webfont.eot');
    src: url('./graphikmedium-webfont.eot?#iefix') format('embedded-opentype'),
         url('./graphikmedium-webfont.woff2') format('woff2'),
         url('./graphikmedium-webfont.woff') format('woff'),
         url('./graphikmedium-webfont.ttf') format('truetype'),
         url('./graphikmedium-webfont.svg#graphikmedium') format('svg');
    font-weight: normal;
    font-style: normal;

}