.property-accordion.ui.accordion {
  margin: 10px -22.5px;
  max-width: none;
  width: calc(100% + 45px);
  border-bottom: 1px solid rgba(184, 181, 181, 0.5);
}
.property-accordion.ui.accordion .title {
  border-top: 1px solid rgba(184, 181, 181, 0.5);
}
.property-accordion.ui.accordion .content {
  margin: 0 22.5px 20px;
}

.signup-desktop-column .property-accordion.ui.accordion {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
.signup-desktop-column .property-accordion.ui.accordion .content {
  margin-left: 0;
  margin-right: 0;
}


