.signup-tip {
  background-color: #f2f2f2;
  border-radius: 1rem;
  margin-top: 3rem;
  padding: 1.5rem 1.2rem 2rem;
}
.signup-tip > .ui.button.signup-tip-button:last-child, .signup-tip > p:last-child {
  margin-bottom: 0;
}

.ui.button.signup-tip-button {
  background-color: white;
  border: 0.0875rem solid rgba(0, 0, 0, 0.6);
  font-size: 1.05em;
  line-height: 1.3;
  margin-bottom: 2rem;
}

.ui.button.signup-tip-button:hover {
  border: 0.0875rem solid rgb(0, 0, 0);
}


