
.experience-settings-form {

  @media(min-width: 992px) {
    max-width: 720px;
    margin: 0 auto;

    .carousel-segment {
      padding: 10px 10px 5px;
    }
  }

  .carousel-segment {
    background: white;
    border-radius: 4px;
    margin: 1em 0px 2em;
    padding: 5px 5px 0px;
  }

  &.wrapper {
    position: relative;
    top: 5vh;
    padding-bottom: 4em;
  }

  .header {
    color: white;
    font-weight: normal;
    text-align: center;
    font-size: 25px;
  }

  .button.cancel-btn, .button.cancel-btn:hover {
    margin: 10px;
    color: white !important;
    background: none !important;
    box-shadow: 0 0 0 1px rgba(255,255,255,.35) inset, 0 0 0 0 rgba(255,255,255,.15) inset;
    width: 75%;

    @media(min-width: 992px) {
      width: 40%;
    }
  }

  .submit-btn {
    margin: 10px;
    width: 75%;

    @media(min-width: 992px) {
      width: 40%;
    }
  }

  .button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.experience-carousel {
  .ui.centered.image {
    border-radius: 4px;
  }

  .slick-dots {
    bottom: -35px;

    li {
      button:before {
        color: white;
        content: '\25CB';
        font-size: 25px;
      }

      &.slick-active button:before {
        color: white;
        content: '\25CF';
      }
    }
  }
}
