.ui.button.signup-button[disabled] {
  background: #C7C7C7;
  opacity: 1 !important;
}
.ui.button.signup-button.link {
  font-weight: 400;
  color: #0060AE;
  border: 1px solid #0060AE;
  background: white;
}


