.property-accordion.ui.accordion {
	margin: 10px -22.5px;
	max-width: none;
	width: calc(100% + 45px);
	.title {
		border-top: 1px solid rgba(184, 181, 181, 0.5);	
	}
	border-bottom: 1px solid rgba(184, 181, 181, 0.5);	

	.content {
		margin: 0 22.5px 20px;
	}
}

.signup-desktop-column {
	.property-accordion.ui.accordion {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
		.content {
			margin-left: 0;
			margin-right: 0;
		}
	}
}
