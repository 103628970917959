.ui.segment.power-hours-intro {
  max-width: 24em;
  margin: auto auto 0.5rem;
}

h5 {
  font-size: 1.1em;
  line-height: 1.4285em;
}

.ui.modal.power-hours-confirmation-modal {
  text-align: center;
  background: #0060AE;
  color: white;
  border-radius: 10px;
}
.ui.modal.power-hours-confirmation-modal > .header {
  padding-top: 4rem !important;
  margin-bottom: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: none;
}
.ui.modal.power-hours-confirmation-modal > .content {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-bottom: 1.5rem !important;
}
.ui.modal.power-hours-confirmation-modal h2 {
  margin-top: 5px;
}
.ui.modal.power-hours-confirmation-modal p {
  font-size: 1.2em;
  line-height: 1.2;
  margin-bottom: 0.3em;
}
.ui.modal.power-hours-confirmation-modal p.address {
  font-family: "graphik";
}
.ui.modal.power-hours-confirmation-modal p.confirmheader {
  font-family: "graphik-medium";
}
.ui.modal.power-hours-confirmation-modal .period {
  text-transform: lowercase;
}
.ui.modal.power-hours-confirmation-modal a {
  color: inherit;
  font-weight: bold;
  text-decoration: underline;
}
.ui.modal.power-hours-confirmation-modal label.terms {
  max-width: 270px;
  margin: 3rem auto 0;
  display: flex;
}
.ui.modal.power-hours-confirmation-modal label.terms .field {
  flex: 0 0 1.5em;
  text-align: left;
  padding-top: 0.2em;
}
.ui.modal.power-hours-confirmation-modal label.terms .ui.checkbox label:before {
  border-radius: 0;
  border-color: #0060ae;
}
.ui.modal.power-hours-confirmation-modal label.terms .ui.checkbox input:checked ~ label:before {
  background: white;
}
.ui.modal.power-hours-confirmation-modal label.terms .ui.checkbox input:checked ~ label:after {
  color: #0060ae;
}
.ui.modal.power-hours-confirmation-modal label.terms span {
  text-align: left;
}
.ui.modal.power-hours-confirmation-modal .button {
  height: 46px;
  border-radius: 4px;
  max-width: 300px;
  margin: 0 auto 0;
}


