.icon-steps {
  margin: 0;
}
.icon-steps.full-width {
  margin: 0 -22.5px;
}
.icon-steps.separators .icon-step {
  border-top: 1px solid rgba(184, 181, 181, 0.5);
}
.icon-steps.separators .icon-step:last-of-type {
  border-bottom: 1px solid rgba(184, 181, 181, 0.5);
}
.icon-steps .icon-step {
  display: flex;
  padding: 20px 22.5px;
}
.icon-steps .icon-step.active {
  background: #FBFBFB;
}
.icon-steps .icon-step .icon-step-icon {
  display: block;
  position: relative;
  width: 41px;
  height: 41px;
  padding: 8px;
  border: 1px solid #888888;
  border-radius: 50%;
  flex: 0 0 auto;
  margin-right: 16px;
}
.icon-steps .icon-step .icon-step-icon svg {
  max-width: 100%;
  max-height: 100%;
}
.icon-steps .icon-step.complete .icon-step-icon {
  border-color: #40CC3D;
}
.icon-steps .icon-step.narrow p.icon-step-title {
  margin-top: 0.45em;
}
.icon-steps .icon-step p.icon-step-title {
  font-size: 18px;
  color: #222222;
  margin-bottom: 1px;
  line-height: 1.28571429em;
}
.icon-steps .icon-step p {
  font-size: 12px;
  line-height: 17px;
  color: #6C6C6C;
}

.signup-desktop-column .icon-steps.full-width {
  margin-left: 0;
  margin-right: 0;
}
.signup-desktop-column .icon-steps .icon-step {
  padding-left: 0;
  padding-right: 0;
}


