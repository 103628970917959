.icon-steps {

	margin: 0;
	
	&.full-width {
		margin: 0 -22.5px;
	}

	&.separators {
		.icon-step {
			&:last-of-type {
				border-bottom: 1px solid rgba(184, 181, 181, 0.5);
			}
			border-top: 1px solid rgba(184, 181, 181, 0.5);
		}	
	}


	.icon-step {
		display: flex;
		padding: 20px 22.5px;

		&.active {
			background: #FBFBFB;
		}
	
		.icon-step-icon {
			display: block;
			position: relative;
			width: 41px;
			height: 41px;
			padding: 8px;
			border: 1px solid #888888;
			border-radius: 50%;
			flex: 0 0 auto;
			margin-right: 16px;

			svg {
				max-width: 100%;
				max-height: 100%;
			}
		}

		&.complete .icon-step-icon {
			border-color: #40CC3D;
		}

		&.narrow {
			p.icon-step-title {
				margin-top: 0.45em;
			}
		}

		p.icon-step-title {
			font-size: 18px;
			color: #222222;
			margin-bottom: 1px;
			line-height: 1.28571429em;
		}

		p {
			font-size: 12px;
			line-height: 17px;
			color: #6C6C6C;
		}
		
	}

}


.signup-desktop-column {
	.icon-steps {
		&.full-width {
			margin-left: 0;
			margin-right: 0;
		}
		.icon-step {
			padding-left: 0;
			padding-right: 0;
		}	
	}
}
