.icon-radio,
.icon-checkbox {

	&.ui {
		display: block;
		height: 100%;
		text-align: center;

		input {
			display: none;
		}
		
		label {
			display: flex;
			flex-direction: column;
			height: 100%;
			color: rgba(0, 0, 0, 0.87);
			border: 2px solid #A09B9B;
			border-radius: 0.5rem;
			padding: 16px 10px 16px;
			cursor: pointer;
			&:before,
			&:after {
				content: none;
			}
		}
		
	}

	input:checked + label {
		background: #E9F5FF;
		border-color: #0060AE;
		color: #0060AE;
	}

	&-icon {		
		margin-top: 10px;
		margin-bottom: 0;
		line-height: 0;
	}
	&-label {		
		margin-top: auto;
		line-height: 0;
		min-height: 44px;
		display: flex;	
		span {
			margin: auto;
			font-size: 15px;
			line-height: 22px;	
		}
	}

}

.ui.form {
	.icon-radio-group,
	.icon-checkbox-group {
		margin-bottom: 0.5em;
		max-width: 500px;
		margin: 0 auto 0.5em;
		.fields {
			display: flex;
			flex-flow: row wrap;
			margin: -0.5em -0.5em 0;
			justify-content: center;

			& > .field {
				width: 50%;
				max-width: 208.5px;
				padding: 0.5em;
			}
		}
		&.wide {
			.fields {
				flex-flow: column;
				& > .field {					
					width: 100%;
					max-width: 417px;
				}
			}

			.icon-radio,
			.icon-checkbox {
				&.ui label {
					flex-direction: row;
					align-items: center;
					padding: 16px 16px;
				}
				&-label {		
					margin: auto 20px auto 0;
					text-align: left;
				}
				&-icon {
					order: 999;
					margin: 0 0 0 auto;
				}
			}			
		}
		legend {
			display: block;
			margin: 5px 0 -5px;
		}	
	}
}