.weather-widget {
	font-size: 14px;

	.weather-row {
		display: flex;
		padding-bottom: 5px;
	}

	svg {
		cursor: default;
		// Contents are ~24 within a 32 svg container, topleft aligned
		transform: scale(1.4, 1.4) translate(1.4 * 3px, 1.4 * 3px);
		margin: 0 3px;
	}

	.temperature {
		font-family: 'graphik-medium', Arial, Helvetica, sans-serif;
		font-size: 25px;
		padding-left: 5px;
		margin: auto 3px 0 auto;

	}
}
.plan__upcomingInstalments.upcomingInstalments {
    
}

p.instalment__header {
padding: 5px 0;
margin:0 ;
}

.main-container .home-loader {
	// loading icon on home page
	align-self: center;
	top: 30px;
  
	@media(min-width: 992px) {
	  top: 10px;
	}
  }
  
  .card-text-size {
	font-size: 35px;
  
	.card-dollar {
	  font-size: 24px;
	}
  
	.card-date {
	  font-size: 24px;
	}
  
	.card-address {
	  font-size: 16px;
	  line-height: 1.6;
	}
  
	.card-life-support {
	  font-size: 16px;
	  line-height: 1.6;
	}
  
  }
  
  @media (max-width: 991px) {
	.ui.inverted.menu .active.item {
	  background: transparent;
	}
  
	.card-text-size {
	  font-size: 30px;
  
	  .card-dollar {
		font-size: 20px;
	  }
  
	  .card-date {
		font-size: 20px;
	  }
  
	  .card-address {
		font-size: 12px;
		line-height: 1.4;
	  }
	  
	  .card-life-support {
		font-size: 12px;
		line-height: 1.4;
	  }
	  
	}
  }
  
  
  .ui.card > .header {
	position: relative;
	@media (min-width: 992px) {
	  font-size: 14px;
	}
  }
  
  .ui.card > .header.home-header-variable-height {
	@media (min-width: 992px) {
	  padding-bottom: 0;
	}
  
	@media (max-width: 370px) {
	  padding-right: 23px;
	}
  }
  
  .ui.card .ui.button {
	border-radius: 4px;
	width:100%;
  }
  
  .ui.cards > .card > .content {
	border: none;
  
	@media (min-width: 992px) {
	  padding: 0;
	}
  
	&.no-padding {
	  padding: 0;
  
	  @media (min-width: 992px) {
		padding: 0;
	  }
	}
  }
  
  .ui.cards > .child_card {
	width: calc(100% - 2em);
	margin: 12px;
  
	@media (min-width: 768px) {
	  margin: 12px;
	  width: calc(50% - 2em);
	}  
  }
  
  .ui.statistic>.value {
	font-size: 3.5rem!important;
  }
  
  .home-info-icon {
	color: grey;
	position: absolute;
	margin: auto;
	top: 0; bottom: 0; right: 5px;
  
	height: 20px;
	width: 20px;
  
	@media (min-width: 991px) {
	  height: 24px;
	  width: 24px;
	}
  }
  
  #home-total-bill-list {
	margin: 0;
	text-transform: none;
	font-size: 25px;
	padding: 1em;
  
	@media (min-width: 768px) {
	  padding: 15px 20px;
	}
  }
  
  #home-no-bill-list {
	margin: 0;
	text-transform: none;
	font-size: 0.36em;
	padding: 1em;
  
	@media (min-width: 768px) {
	  font-size: 0.45em;
	  padding: 1.25em 1.6em;
	}
  
	&.alternate {
	  text-align: center;
	  padding: 1em 0;
	  font-size: 1.7em;
	}
  }
  
  #time-of-use-indicator-text {
	font-size: 2.5em !important;
  }
  
  .no-card-paragraph {
	  text-align: center;
	  font-size: 1em;
	  @media (min-width: 768px) {
		  font-size: 1.2em;
	  }
	  margin-bottom: 2em;
  }
  @media (min-width: 768px) {
	  .account-picker-wrapper + div .no-card-paragraph:first-child {
		  margin-top: -1.5rem;
	  }
  }



  // Payment plans

.ui.stackable.cards .ui.card.payment-plan {
	// override semantic ui !important :(
	@media only screen and (max-width: 767px) {
		&:first-child {
			margin-top: 10px !important;
		}
	}
}



.advancePayments {
    padding: 10px 0 15px 0;
}

.ui.card.payment-plan {
	text-align: center;

	.plan {

		&__header {
			font-size: 20px;
			padding: 0 0 20px 0;
			margin: 0;
			
		}
	
		&__paynow-btn {
			width: 100%;
			border-radius: 5px;
			margin: 5% auto;
		}
	
	}

	.instalment {
		&__content {
			display: flex;
			& > div {
				flex: 1 1 0;
				padding: 20px 10px;
			}
		}
		&__text {
			font-size: 36px;
			line-height: 36px;
			margin: 0;
			height:24px;
			font-family: "graphik-medium", Arial, Helvetica, sans-serif;
		}
		&__subheader {
			font-size: 12px;
			margin: 0;
		}
	}

  
	.upcomingInstalments {
		&__header {
			font-size: 12px;
			padding: 0px;
			border-top-right-radius: 5px;
			border-top-left-radius: 5px;
			display: flex;
			justify-content: space-between;
			align-items: center;
	
			p {
				margin: 0;
			}
		}
	
		&__body {
			padding: 8px 0;
		}
	
		&__list-item {
			padding: 4px 2px;
			display: flex;
			justify-content: space-between;
	
			p {
				margin: 0;
			}
		}
	}
  
	.load-more {
	  margin-bottom: 12px;
	  color: inherit;
	  background: none;
	  font-size: 12px;
	}
  }