.ui.items.notifications {
  h4 {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 2px;
    line-height: 1.3;
    font-family: "graphik-medium";
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased; 
  }

  .item.notification.concessions-section {
    .content {
      .description {
        .concession-number {
          margin-bottom: 14px;
          
          div {
            font-size: 14px;
          }
        }

        .concession-row {
          display: flex;
          justify-content: space-between;

          .concession-type {
            margin-bottom: 15px;
            margin-right: 5px;
            
            div {
              font-size: 14px;
              margin-bottom: 0px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          .concession-status {
            text-align: left;
            
            div {
              font-size: 14px;
              margin-bottom: 0px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        .no-concessions {
          h2 {
            font-size: 24px;
            margin-bottom: 20px;
          }

          p {
            font-size: 14px;
            line-height: 1.5;
            margin-top: 5px;
          }

          .add-concession-button {
            color: white;
            padding: 20px;
            font-size: 18px;
            border-radius: 8px;
          }
        }
      }
    }
  }
}
.ui.items > .item > .content p {
    margin-bottom: 18px;
}
.ui.items > .item.notification > .content {
    margin-bottom: 0px
}

.header{
    font-family: "graphik-medium";

}

// Common styles for account details sections
.account-number,
.primary-account,
.abn,
.life-support {
  margin-bottom: 15px;

  h4 {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 5px;
    font-family: "graphik-medium";
    -webkit-font-smoothing: antialiased;
  }

  div:not(.life-support) {
    font-size: 14px;
    line-height: 1.4;
  }
}

// Specific styles for life support section
.life-support-section {
  margin-bottom: 15px;

  .life-support {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3px;

    h4 {
      font-size: 14px;
      font-weight: normal;
      margin: 0;
      font-family: "graphik-medium";
      -webkit-font-smoothing: antialiased;
    }

    .info-icon {
      height: 18px;
      cursor: pointer;
      color: var(--theme-links-color, #0060ae);
      margin-left: 5px;
    }
  }

  .life-support-text {
    font-size: 14px;
    line-height: 1;
    margin-top: 0;
  }
}

.notification {
  .contact-details {
    margin-bottom: 20px;

    h4 {
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 5px;
      font-family: "graphik-medium";
      -webkit-font-smoothing: antialiased;
    }

    .contact-item {
      font-size: 14px;
      line-height: 1.4;
      margin-bottom: 20px;


      .edit-link {
        color: var(--theme-primary-color);
        text-decoration: none;
        font-weight: 500;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.contact-section {
    margin-top: 0px;
    
    h4{
    margin-top: 0;
    }
    .edit-link {
      color: var(--theme-primary-color);
      text-decoration: none;
      font-family: 'graphik-medium';
      
      &:hover {
        color: var(--theme-link-hover-color);
      }
    }
  }
}

/// This over-rides a Semantic spacing issue
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ui.items > .item {
      margin: 0 0em;
  }
}

.concession-type {
  .concession-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    @media (max-width: 340px) {
      max-width: 180px;
    }
  }
}

.email-container {
  width: 100%;
  text-align: center;
  display: inline-block;

  .email-link {
    word-break: break-word;
    overflow-wrap: break-word;
    display: inline-block;
    margin: 10px 0;
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.3s ease;
    min-width: 300px;
    text-align: center;
    font-family: "graphik-medium";

    &.copied {
      text-decoration: none;  // Remove underline when showing "copied" message
    }
  }
}