.signup-hr {
  margin: 10px -22.5px;
  border: none;
  border-top: 1px solid rgba(184, 181, 181, 0.5);
}
.signup-hr.light {
  opacity: 0.5;
}
.signup-hr.spaced {
  margin-top: 45px;
  margin-bottom: 45px;
}
.signup-hr.space-above {
  margin-top: 45px;
}
.signup-hr.space-below {
  margin-bottom: 45px;
}

.signup-desktop-column .signup-hr {
  margin-left: 0;
  margin-right: 0;
}


