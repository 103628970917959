.ui.SolarSystemSizeGrid {
  margin-bottom: 0rem;
}
@media (max-width: 991px) {
  .ui.SolarSystemSizeGrid {
    margin-bottom: initial;
  }
}
.ui.SolarSystemSizeGrid .row {
  padding: 0.5rem 0rem;
}
.ui.SolarSystemSizeGrid .row:nth-child(odd) {
  background: var(--alternate-background) !important;
}
.ui.SolarSystemSizeGrid .SystemSize,
.ui.SolarSystemSizeGrid .SystemKwhSize {
  font-size: 0.75rem;
}
@media (max-width: 991px) {
  .ui.SolarSystemSizeGrid .SystemSize,
  .ui.SolarSystemSizeGrid .SystemKwhSize {
    font-size: 0.75rem;
  }
}

.SolarSystemTableDescription {
  font-size: 0.75rem;
  font-weight: bold;
}
@media (max-width: 991px) {
  .SolarSystemTableDescription {
    font-size: 0.5rem;
  }
}

.SolarSystemSizeConditions {
  margin-top: 3rem;
}
@media (max-width: 991px) {
  .SolarSystemSizeConditions {
    padding: 0rem 0.5rem;
    margin-top: 0;
  }
}
.SolarSystemSizeConditions .SolarSystemSizeConditionsText {
  font-size: 0.75rem;
}

.ui.header.SolarSystemSizeHeader {
  font-size: 1.275rem;
  font-weight: normal;
}
@media (max-width: 991px) {
  .ui.header.SolarSystemSizeHeader {
    font-size: 1.1rem;
  }
}


