.usage-summary-other {
  position: relative;
}

.usage-summary-other.clickable {
  cursor: pointer;
}

.usage-summary-other > td {
  
}

.usage-summary-other > td:last-child {
  text-align: right;
}

.usage-summary-other > td > i {
  padding-left: 5px;
}
