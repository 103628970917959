.terms-of-service {
	font-size: 0.867em;

	h1 {
		font-size: 1.5em;
	}
	h2 {
		font-size: 1.2em;
	}
	h3 {
		font-size: 1em;
		margin-bottom: 1em;
	}

	p {
		font-size: 1em;
		margin-bottom: 1em;
		line-height: 1.6;

		&.justified {
			text-align: justify;
		}
	}

	.alpha-list {
		list-style-type: lower-alpha;
		padding-left: 1em;
		li {
			margin-bottom: 1em;
			text-align: justify;
		}
	}
	.roman-list {
		list-style-type: lower-roman;
		padding-left: 3em;
		li {
			margin-bottom: 1em;
			text-align: justify;
		}
	}
}
