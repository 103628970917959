.login-page .image-wrapper {
  position: relative;
  min-height: 226px;
  height: 30vw;
  max-height: 550px;
  overflow: hidden;
}
.login-page .image-wrapper img.ui.image {
  object-fit: cover;
  object-position: right center;
  min-width: 100%;
  min-height: 100%;
}
.login-page .login-wrapper {
  padding: 30px 0 30px;
}
.login-page .login-wrapper p {
  color: #0060ae;
  font-size: 22px;
}
.login-page .signup-wrapper {
  background: #0060ae;
  padding: 40px 0 60px;
  flex: 1 1 0;
}
.login-page .signup-wrapper p {
  color: white;
  font-size: 22px;
}
.login-page .logo {
  margin-top: 50px;
  border-top: 1px solid white;
  background: #0060ae;
  display: flex;
}
.login-page .logo img.ui.image {
  width: 100px;
  margin: 60px auto 0;
}
.login-page .forgot-password {
  display: inline-block;
  text-decoration: underline;
  margin-top: 30px;
}


