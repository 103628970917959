.login-page {

	.image-wrapper {
		position: relative;
		min-height: 226px;
		height: 30vw;
		max-height: 550px;
		overflow: hidden;
		
		img.ui.image {
			object-fit: cover;
			object-position: right center;
			min-width: 100%;
			min-height: 100%;
		}

	}

	.login-wrapper {
		padding: 30px 0 30px;
		p {
			color: #0060ae;
			font-size: 22px;
		}
	}

	.signup-wrapper {
		background: #0060ae;
		padding: 40px 0 60px;
		flex: 1 1 0;	
		p {
			color: white;		
			font-size: 22px;
		}
	}

	.logo {
		margin-top: 50px;
		border-top: 1px solid white;
		background: #0060ae;
		display: flex;

		img.ui.image {
			width: 100px;
			margin: 60px auto 0;
		}
	}

	.forgot-password {
		display: inline-block;
		text-decoration: underline;
		margin-top: 30px;
	}
}