.ui.menu.bottom.footer {
    border-radius: 0;
    border: none;
    font-size: 0.875em;

    .item > a {
        font-size: 0.675em;

    }
}
