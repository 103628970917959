.SolarHeader {
    display: inline-block;
    width: 100%;
    margin-bottom: -1rem;

    .SolarHeaderToggle {
        float: left;

        .SolarToggle {
            position: relative;

            label:before {
                width: 5.5rem;
                height: 1.25rem;
                
            }

            label:after {
                width: 1.25rem;
                height: 1.25rem;
            }
        }

        .SolarHeader .SolarHeaderToggle .SolarToggle label:before,
        .ui.toggle.checkbox input:focus~.box:before, .ui.toggle.checkbox input:focus~label:before,
        .ui.toggle.checkbox .box:hover::before, .ui.toggle.checkbox label:hover::before{
            background-color: #f3f3f3;
            transition: background-color .3s ease;
        }

        .ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before {
            background-color: #faa63d !important;
        }

        .ui.toggle.checkbox input:checked~.box:after, .ui.toggle.checkbox input:checked~label:after {
            left: 4.25rem;
        }

        .SolarHeaderToggleTitle{
            position: absolute;
            width: 5.5rem;
            height: 1.5rem;
            text-align: center;
            z-index: 2;
            font-size: .75rem;
            font-weight: 600;
            margin-top: -0.05rem;
            cursor: pointer;
            
            &:focus {
                outline: none;
            }

            &.checked {
                color: #fff;
                transition: color .3s ease;
            }
        }
    }

    .SolarHeaderInfo {
        float: right;
        width: 7rem;
        cursor: pointer;

        .SolarHeaderInfoIcon {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 5px;
            background-color: #F5A623;
            float: left;

            svg {
                color: #fff;
                margin-left: .25rem;
                margin-top: .25rem;
            }
        }

        .SolarHeaderInfoText {
            float: right;
            width: 5.5rem;
            padding-left: .5rem; 
            p {
                font-size: .5rem;
            }
        }
    }

    
}