.terms-of-service {
  font-size: 0.867em;
}
.terms-of-service h1 {
  font-size: 1.5em;
}
.terms-of-service h2 {
  font-size: 1.2em;
}
.terms-of-service h3 {
  font-size: 1em;
  margin-bottom: 1em;
}
.terms-of-service p {
  font-size: 1em;
  margin-bottom: 1em;
  line-height: 1.6;
}
.terms-of-service p.justified {
  text-align: justify;
}
.terms-of-service .alpha-list {
  list-style-type: lower-alpha;
  padding-left: 1em;
}
.terms-of-service .alpha-list li {
  margin-bottom: 1em;
  text-align: justify;
}
.terms-of-service .roman-list {
  list-style-type: lower-roman;
  padding-left: 3em;
}
.terms-of-service .roman-list li {
  margin-bottom: 1em;
  text-align: justify;
}


