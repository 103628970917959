.time-of-use-graph {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  color: #0060AE;
}
.time-of-use-graph .peak {
  color: #F7902B;
}
.time-of-use-graph .off-peak {
  color: #0060AE;
}
.time-of-use-graph header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 10px 12px;
}
.time-of-use-graph header h2 {
  font-size: 23px;
  margin: 0;
}
.time-of-use-graph header h2 svg {
  margin-left: 0.5em;
  position: relative;
  top: 4px;
}
.time-of-use-graph .time-of-use-labels {
  position: relative;
  height: 1em;
  margin-bottom: 15px;
}
.time-of-use-graph .time-of-use-label {
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}
.time-of-use-graph .time-of-use-timeline {
  position: relative;
  height: 3px;
}
.time-of-use-graph .time-of-use-timeline div {
  position: absolute;
  top: 0;
  height: 3px;
  border-radius: 1.5px;
}
.time-of-use-graph .time-of-use-timeline div.peak {
  background: #F7902B;
}
.time-of-use-graph .time-of-use-timeline div.off-peak {
  background: #0060AE;
}
.time-of-use-graph .time-of-use-timeline div.shoulder {
  background: #0060AE;
}
.time-of-use-graph .time-of-use-now {
  position: absolute;
  left: 50%;
  margin-top: 5px;
  margin-left: -9px;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 16px solid;
}
.time-of-use-graph .time-of-use-now.peak {
  border-bottom-color: #F7902B;
}
.time-of-use-graph .time-of-use-now.off-peak {
  border-bottom-color: #0060AE;
}


