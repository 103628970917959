.ui.items.notifications h4 {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 2px;
  line-height: 1.3;
  font-family: "graphik-medium";
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
}
.ui.items.notifications .item.notification.concessions-section .content .description .concession-number {
  margin-bottom: 14px;
}
.ui.items.notifications .item.notification.concessions-section .content .description .concession-number div {
  font-size: 14px;
}
.ui.items.notifications .item.notification.concessions-section .content .description .concession-row {
  display: flex;
  justify-content: space-between;
}
.ui.items.notifications .item.notification.concessions-section .content .description .concession-row .concession-type {
  margin-bottom: 15px;
  margin-right: 5px;
}
.ui.items.notifications .item.notification.concessions-section .content .description .concession-row .concession-type div {
  font-size: 14px;
  margin-bottom: 0px;
}
.ui.items.notifications .item.notification.concessions-section .content .description .concession-row .concession-type div:last-child {
  margin-bottom: 0;
}
.ui.items.notifications .item.notification.concessions-section .content .description .concession-row .concession-status {
  text-align: left;
}
.ui.items.notifications .item.notification.concessions-section .content .description .concession-row .concession-status div {
  font-size: 14px;
  margin-bottom: 0px;
}
.ui.items.notifications .item.notification.concessions-section .content .description .concession-row .concession-status div:last-child {
  margin-bottom: 0;
}
.ui.items.notifications .item.notification.concessions-section .content .description .no-concessions h2 {
  font-size: 24px;
  margin-bottom: 20px;
}
.ui.items.notifications .item.notification.concessions-section .content .description .no-concessions p {
  font-size: 14px;
  line-height: 1.5;
  margin-top: 5px;
}
.ui.items.notifications .item.notification.concessions-section .content .description .no-concessions .add-concession-button {
  color: white;
  padding: 20px;
  font-size: 18px;
  border-radius: 8px;
}

.ui.items > .item > .content p {
  margin-bottom: 18px;
}

.ui.items > .item.notification > .content {
  margin-bottom: 0px;
}

.header {
  font-family: "graphik-medium";
}

.account-number,
.primary-account,
.abn,
.life-support {
  margin-bottom: 15px;
}
.account-number h4,
.primary-account h4,
.abn h4,
.life-support h4 {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 5px;
  font-family: "graphik-medium";
  -webkit-font-smoothing: antialiased;
}
.account-number div:not(.life-support),
.primary-account div:not(.life-support),
.abn div:not(.life-support),
.life-support div:not(.life-support) {
  font-size: 14px;
  line-height: 1.4;
}

.life-support-section {
  margin-bottom: 15px;
}
.life-support-section .life-support {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
}
.life-support-section .life-support h4 {
  font-size: 14px;
  font-weight: normal;
  margin: 0;
  font-family: "graphik-medium";
  -webkit-font-smoothing: antialiased;
}
.life-support-section .life-support .info-icon {
  height: 18px;
  cursor: pointer;
  color: var(--theme-links-color, #0060ae);
  margin-left: 5px;
}
.life-support-section .life-support-text {
  font-size: 14px;
  line-height: 1;
  margin-top: 0;
}

.notification .contact-details {
  margin-bottom: 20px;
}
.notification .contact-details h4 {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 5px;
  font-family: "graphik-medium";
  -webkit-font-smoothing: antialiased;
}
.notification .contact-details .contact-item {
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 20px;
}
.notification .contact-details .contact-item .edit-link {
  color: var(--theme-primary-color);
  text-decoration: none;
  font-weight: 500;
}
.notification .contact-details .contact-item .edit-link:hover {
  text-decoration: underline;
}
.notification.contact-section {
  margin-top: 0px;
}
.notification.contact-section h4 {
  margin-top: 0;
}
.notification.contact-section .edit-link {
  color: var(--theme-primary-color);
  text-decoration: none;
  font-family: "graphik-medium";
}
.notification.contact-section .edit-link:hover {
  color: var(--theme-link-hover-color);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ui.items > .item {
    margin: 0 0em;
  }
}
.concession-type .concession-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 340px) {
  .concession-type .concession-text {
    max-width: 180px;
  }
}

.email-container {
  width: 100%;
  text-align: center;
  display: inline-block;
}
.email-container .email-link {
  word-break: break-word;
  overflow-wrap: break-word;
  display: inline-block;
  margin: 10px 0;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;
  min-width: 300px;
  text-align: center;
  font-family: "graphik-medium";
}
.email-container .email-link.copied {
  text-decoration: none;
}


