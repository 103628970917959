.SolarHeader {
  display: inline-block;
  width: 100%;
  margin-bottom: -1rem;
}
.SolarHeader .SolarHeaderToggle {
  float: left;
}
.SolarHeader .SolarHeaderToggle .SolarToggle {
  position: relative;
}
.SolarHeader .SolarHeaderToggle .SolarToggle label:before {
  width: 5.5rem;
  height: 1.25rem;
}
.SolarHeader .SolarHeaderToggle .SolarToggle label:after {
  width: 1.25rem;
  height: 1.25rem;
}
.SolarHeader .SolarHeaderToggle .SolarHeader .SolarHeaderToggle .SolarToggle label:before,
.SolarHeader .SolarHeaderToggle .ui.toggle.checkbox input:focus ~ .box:before, .SolarHeader .SolarHeaderToggle .ui.toggle.checkbox input:focus ~ label:before,
.SolarHeader .SolarHeaderToggle .ui.toggle.checkbox .box:hover::before, .SolarHeader .SolarHeaderToggle .ui.toggle.checkbox label:hover::before {
  background-color: #f3f3f3;
  transition: background-color 0.3s ease;
}
.SolarHeader .SolarHeaderToggle .ui.toggle.checkbox input:checked ~ .box:before, .SolarHeader .SolarHeaderToggle .ui.toggle.checkbox input:checked ~ label:before {
  background-color: #faa63d !important;
}
.SolarHeader .SolarHeaderToggle .ui.toggle.checkbox input:checked ~ .box:after, .SolarHeader .SolarHeaderToggle .ui.toggle.checkbox input:checked ~ label:after {
  left: 4.25rem;
}
.SolarHeader .SolarHeaderToggle .SolarHeaderToggleTitle {
  position: absolute;
  width: 5.5rem;
  height: 1.5rem;
  text-align: center;
  z-index: 2;
  font-size: 0.75rem;
  font-weight: 600;
  margin-top: -0.05rem;
  cursor: pointer;
}
.SolarHeader .SolarHeaderToggle .SolarHeaderToggleTitle:focus {
  outline: none;
}
.SolarHeader .SolarHeaderToggle .SolarHeaderToggleTitle.checked {
  color: #fff;
  transition: color 0.3s ease;
}
.SolarHeader .SolarHeaderInfo {
  float: right;
  width: 7rem;
  cursor: pointer;
}
.SolarHeader .SolarHeaderInfo .SolarHeaderInfoIcon {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 5px;
  background-color: #F5A623;
  float: left;
}
.SolarHeader .SolarHeaderInfo .SolarHeaderInfoIcon svg {
  color: #fff;
  margin-left: 0.25rem;
  margin-top: 0.25rem;
}
.SolarHeader .SolarHeaderInfo .SolarHeaderInfoText {
  float: right;
  width: 5.5rem;
  padding-left: 0.5rem;
}
.SolarHeader .SolarHeaderInfo .SolarHeaderInfoText p {
  font-size: 0.5rem;
}


