.step-content {
  max-width: 900px;
  margin: 0 auto;
  padding: 0.4em;
  min-height:600px;
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: 1.5em;
    font-size: 16px;
  }

  p {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 1rem;
  }

  .button-group {
    display: flex;
    gap: 1em;
    justify-content: center;
    margin-top: 2em;
  }

  .input-field {
    margin-bottom: 2em;

    label {
      display: block;
      margin-bottom: 0.5em;
      font-weight: bold;
    }

    input {
      width: 100%;
      padding: 0.8em;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 16px;

      &::placeholder {
        color: #999;
      }
    }
  }

  .terms-container {
    max-height: 300px;
    overflow-y: scroll;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 0.5rem;
    background-color: var(--terms-background-color);

    .terms-content {
      p {
        margin-bottom: 1rem;
        line-height: 1.6;
        white-space: pre-wrap;
      }

      ul, ol {
        margin-bottom: 1rem;
        padding-left: 2rem;

        li {
          margin-bottom: 0.5rem;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 8px;
      display: block;
    }

    &::-webkit-scrollbar-track {
      background: rgba(128, 128, 128, 0.1);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--primary-color, #0060AE);
      border-radius: 4px;
      border: 2px solid transparent;
      background-clip: padding-box;
      
      &:hover {
        background: var(--primary-color-hover, #005295);
      }
    }

    // For Firefox
    scrollbar-width: thin;
    scrollbar-color: var(--primary-color) transparent;
  }

  .radio-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 1.5rem 0;

    .radio-item {
      input[type="radio"] {
        display: none;

        &:checked + label {
          background-color: var(--selected-background-color, #0060AE);
          border-color: var(--selected-border-color, #0060AE);
          color:#FFFFFF;
        }
      }

      label {
        display: block;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid var(--border-color, #0060AE);
        cursor: pointer;
        font-size: 15px;
        transition: all 0.2s ease;
      }
    }
  }

  .customer-name {
    font-size: 16px;
    margin: -1em 0 2em 0;
    padding-bottom: 1em;
    border-bottom: 1px solid #eee;
  }

  .select-buttons {
    margin-top: 0rem;
    padding-top: 0.5em;
    
    .button-row {
      display: flex;
      gap: 10px;
      margin-bottom: 10px;
    }
    
    .select-button {
      width: 100%;
      padding: 15px !important;
      border-radius: 10px !important;
      border-width: 1px !important;
      border-style: solid !important;
      font-size: 15px;
      font-weight: normal !important;
      margin: 0 !important;
      transition: opacity 0.2s !important;
      
      &:active {
        opacity: 0.8;
      }
      
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &.advance {
        
        color: #FFFFFF;
        background-color: #F7902B;
        border-color:  #F7902B!important;
        
        &:hover {
          opacity: 0.9;
          
        }
        
        &:focus {
          
        }
      }
      &.cancel {
        
        color: #000000;
        background-color: #F4F4F4;
        border-color:  #CCCCCC!important;
        
        &:hover {
          opacity: 0.9;
          
        }
        
        &:focus {
          
        }
      }
    }
  }

  .checkbox-container {
    margin: 20px 0;
    display: flex;
    align-items: flex-start;
    gap: 10px;

    input[type="checkbox"] {
      margin-top: 4px;
      cursor: pointer;
      width: 24px;
      height: 24px;
      &:focus {
        outline: 2px solid var(--primary-color);
      }
    }

    label {
      font-size: 14px;
      line-height: 1.4;
      cursor: pointer;
      flex: 1;
      color: inherit;
    }
  }

  .submission-summary {
    .summary-details {
      p {
        margin-bottom: 10px !important;
        font-size: 14px;
        line-height: 1.5;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .step-header {
    font-family: "graphik";
    margin-bottom: 1rem !important;
    font-size: 19px !important;
  }

  .success-tick {
    width: 120px;
    height: 120px;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-color, #0060AE);
    
    svg {
      width: 100%;
      height: 100%;
    }
  }
} 