.ui.menu.bottom.footer {
  border-radius: 0;
  border: none;
  font-size: 0.875em;
}
.ui.menu.bottom.footer .item > a {
  font-size: 0.675em;
}


