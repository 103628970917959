.main-header.ui.menu {
  border-color: transparent !important;
  border-bottom-color: rgba(255, 255, 255, 0.3) !important;
  border-bottom-width: 1px !important;
}
.main-header.ui.menu.blue-header {
  background: #0060AE;
}
.main-header.ui.menu.blue-header svg g {
  fill: white !important;
}
.main-header.ui.menu .item {
  padding: 1.75em 0.8em;
  height: 88px;
  display: flex;
  align-items: flex-end;
}
.main-header.ui.menu .item:first-child {
  padding-left: 0;
}
.main-header.ui.menu .item:last-child {
  padding-right: 0;
}
.main-header.ui.menu .item.right svg {
  margin: 0 0 0 10px;
}
.main-header.ui.menu .item.header + .item {
  margin-left: 1.5rem;
}
.main-header.ui.menu .nav-icon {
  padding: 0.4em 0 0 1em;
  margin: 0 0.5em;
  display: flex;
  align-items: center;
}
.main-header.ui.menu .nav-icon svg {
  height: 24px;
  width: 24px;
  margin: 0;
}
.main-header.ui.menu .main-header .menu-item-icon {
  font-size: 90%;
  display: flex;
  align-items: center;
}
.main-header.ui.menu a.item.menu-item-icon > span {
  margin-left: 8px;
  font-size: 0.85em;
}
.main-header.ui.menu .right.menu > .fresnel-container {
  display: flex;
}
.main-header.ui.menu .ui.checkbox label {
  color: white !important;
}
.main-header.ui.menu .header.item {
  align-self: baseline !important;
  background: none;
}
.main-header.ui.menu .header.item.active {
  background: none;
}
.main-header.ui.menu.mobile {
  height: 61px;
}
.main-header.ui.menu.compressed.pointing {
  border-width: 0;
}
.main-header.ui.menu.compressed a.item {
  height: 88px;
  font-size: 12px;
  line-height: 109.5%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}
.main-header.ui.menu.compressed a.item:not(.nav-icon) {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 110px;
}
.main-header.ui.menu.compressed a.item:not(.nav-icon) > svg {
  margin: 0 0 1em 0;
}
.main-header.ui.menu.compressed a.item.header {
  padding: 10px 0px !important;
  flex-direction: row;
}
.main-header.ui.menu.compressed a.item.active {
  font-weight: normal;
}
.main-header.ui.menu.compressed .product-picker {
  margin-left: auto;
  display: flex;
}
.main-header.ui.menu.compressed .product-picker .item {
  color: white !important;
  width: 100px;
}
.main-header.ui.menu.compressed .product-picker .item.active {
  background: rgba(51, 51, 51, 0.1);
  mix-blend-mode: multiply;
  box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-color: transparent;
}
.main-header.ui.menu.mobile {
  margin: 0;
  height: 61px;
  padding: 0 12px;
}
.main-header.ui.menu.mobile .header.item {
  height: 61px;
  display: flex;
  align-items: center;
  margin-left: 0.3rem !important;
}
.main-header.ui.menu.mobile .header.item img {
  height: 32px;
}
.main-header.ui.menu.mobile .header.item .icon {
  top: 0;
  height: 61px;
  line-height: 61px;
}
.main-header.ui.menu.mobile .header.item .item.header + .item {
  margin-left: 0rem;
}
.main-header.ui.menu.mobile a.item {
  color: inherit !important;
  height: 61px;
  display: inline-flex;
  margin-left: 0rem !important;
}
.main-header.ui.menu.mobile.compressed .product-picker {
  width: 100%;
  justify-content: center;
}
.main-header.ui.menu.mobile.compressed .product-picker .item {
  margin: 0;
  padding: 0 !important;
  width: 50% !important;
  height: 77px !important;
  flex-direction: row;
  border-radius: 0;
}
.main-header.ui.menu.mobile.compressed .product-picker .item:first-child:not(.header) {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0;
}
.main-header.ui.menu.mobile.compressed .product-picker .item > svg {
  margin: 0 0 0 2em;
}
.main-header.ui.menu.mobile + .menu {
  height: 77px;
}
.main-header.ui.menu .right-icons {
  display: flex;
  align-items: center;
}
.main-header.ui.menu .white-page .main-header.ui.menu {
  background: #0060AE;
  border-radius: 0;
}

.count {
  border-radius: 50%;
  background: #FF4949;
  width: 1rem;
  height: 1rem;
  font-size: 0.75em;
  color: white;
  text-align: center;
  padding: 0.125rem;
  margin-left: -1em;
}


