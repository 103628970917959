.deeplink-launch-page .launch-box {
  text-align: center;
  padding: 2rem 1.5rem 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  .deeplink-launch-page .launch-box {
    min-height: 100%;
    background: white;
  }
}
@media (min-width: 768px) {
  .deeplink-launch-page .launch-box {
    width: 420px;
    margin: auto;
    background: rgba(255, 255, 255, 0.88);
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}
.deeplink-launch-page h1 {
  color: #0060AE;
  max-width: 10em;
  margin: 0 auto 2.5rem;
}
.deeplink-launch-page .deeplink-graphic {
  margin: 0 auto 2rem;
}
.deeplink-launch-page .launch-button {
  padding: 18px;
  margin: 0 0 2rem;
}
.deeplink-launch-page .ui.fluid.button.continue-in-browser {
  padding: 18px;
  box-shadow: 0px 0px 0px 2px #0060AE inset !important;
  color: #0060AE;
  margin: 0 0 5rem;
}
.deeplink-launch-page .learn-more {
  font-size: 18px;
  line-height: 1.6;
  color: #0060AE;
}
.deeplink-launch-page .learn-more a {
  display: block;
  text-decoration: underline;
}
.deeplink-launch-page .logo {
  margin-top: 2rem;
  display: flex;
}
.deeplink-launch-page .logo img.ui.image {
  width: 200px;
  margin: 0 auto 0;
}


