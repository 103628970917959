.ui.form > p.verify-prompt {
  margin-bottom: 30px;
}

.otp-group {
  display: flex;
  width: 100%;
  max-width: 400px;
  margin: 0 auto 50px;
  column-gap: 11px;
}
.otp-group input[type=text].otp-input {
  width: 100%;
  height: 80px;
  border: 0.0875rem solid rgba(0, 0, 0, 0.6) !important;
  border-radius: 0.25rem;
  padding: 10px 2px 2px;
  text-align: center;
  font-size: 31px;
  line-height: 1;
}
.otp-group .otp-troubleshoot {
  background-color: #f2f2f2;
  border-radius: 1rem;
  margin-top: 3rem;
  padding: 2rem 1rem;
}
.otp-group .otp-button {
  background-color: white !important;
  border: 0.0875rem solid rgba(0, 0, 0, 0.6) !important;
}
.otp-group .otp-button:hover {
  border: 0.0875rem solid rgb(0, 0, 0) !important;
}


