.request-deletion section {
  margin-bottom: 1em;
}
.request-deletion section ul {
  margin: 0;
  padding-inline-start: 1.7em;
}
.request-deletion section ul ul {
  list-style-type: disc;
}
.request-deletion section:last-of-type {
  margin-bottom: 3em;
}
.request-deletion .error-message {
  color: red;
}


