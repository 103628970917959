.mobile-navigation a.item {

  // When the item is active
  &.active {
    svg {
      opacity: 1; 
      color: #F78F28;
    }
  }

  // When the item is hovered
  &:hover {
    svg {
      opacity: 1;
      color: #F78F28;
    }
  }

  // When the item is clicked (active state)
  &:active {
    svg {
      opacity: 1; 
      color: #F78F28
    }
  }

  // For default state (if you want a non-active state to also have 50%)
  svg {
    opacity: 1; 
  }

  // Styles for the .count element
  .count {
    align-self: flex-start;
    border-radius: 50%;
    background: #ed2f2f;
    width: 1rem;
    height: 1rem;
    font-size: 0.75em;
    color: white;
    text-align: center;
    padding: 0.125rem;
    margin-left: -1em;
  }
}
