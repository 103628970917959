@media (max-width: 767px) {
  .ui.basic.selection.dropdown.usage-dropdown {
    margin-top: 1rem;
  }
}
@media (min-width: 768px) {
  .ui.basic.selection.dropdown.usage-dropdown {
    width: 10em;
  }
}

.ui.header.usage-header {
  margin: 0;
  font-size: 16px;
  font-weight: normal;
}

.usage-summary-table.ui.table tr td {
  border: none;
  font-weight: 400;
  color: inherit !important;
}

@media (max-width: 991px) {
  .usage-graph-header.ui.header {
    font-size: 1em;
  }
}

.chart-legends {
  display: flex;
  justify-content: center;
}
.chart-legends > .label {
  background-color: transparent;
  padding: 0.5rem 0.25rem;
}
.chart-legends > .ui.label {
  color: inherit;
}
.chart-legends > .ui.label > .detail {
  margin-left: 0.5em;
  font-weight: 400;
}
.chart-legends > .ui.label > .detail > img {
  margin-top: -4px;
  width: 22px;
}
@media (max-width: 460px) {
  .chart-legends > .ui.label > .detail {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .chart-legends > .ui.label > .detail > svg {
    width: 1rem;
  }
}


