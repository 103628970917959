@import "../../sass/functions";
@import "../../sass/variables";

$peakColor: #f1853c;
$offPeakColor: rgba(241, 133, 60, 0.4);
$yAxisTickColor: #f3f3f3;
$SolarColor: #00d06b;

@keyframes bounce {
  // https://raw.githubusercontent.com/daneden/animate.css/master/animate.css
  from, 20%, 53%, 80%, to {
    transform: translateX(0%);
  }

  40%, 43% {
    transform: translateX(-5%);
  }

  65% {
    transform: translateX(-3%);
  }

  90% {
    transform: translateX(-1%);
  }
}

.grouped-bar-chart {
  position: relative;

  svg {
    fill: transparent;
    pointer-events: all;

    .more-data-right-arrow {
      opacity: 0;
      transform: translate(90%, 23%);

      @media(max-width: 767px) {
        transform: translate(80%, 23%);
      }
        
      &.showing {
        opacity: 1;
        transition: opacity .2s ease-in;

        .animated-transform {
          animation: bounce 1000ms .5s ease;
        }
      }

      path {
        stroke: rgba(0,0,0,0.19);
        stroke-width: 1.5px;
      }
    }

    text {
      font-weight: 400;
    }
  }

  .axis {
    fill: none;
    font-size: 10px;
    font-family: sans-serif;

    path {
      display: none;
    }

    .tick {
      //each tick and associated coloured row
      opacity: 1;

      line {
        stroke: transparent;
        stroke-width: 0;
      }

      text {
        font-weight: 400;
        user-select: none;
      }
    }

    &.x-axis {
      text-anchor: middle;
      cursor: pointer;

      .domain {
        display: none;
      }

      text {
        font-size: 12px;
      }
    }

    &.y-axis {
      text-anchor: end;

      .axis-label {
        font-size: 12px;
        text-anchor: middle;
      }

      .odd line {
        stroke: var(--alternate-background) !important;
      }
    }
  }

  .bar-group {
    cursor: pointer;
  }

  .errorMessage {
    text-align: center;
    position: absolute;
    // bottom: 55%;
    font-size: 0.8rem;
    padding: 0 5em;
    transform: translateY(50%);
    width: 100%;
    user-select: none;

    @media(max-width: 991px) {
      font-size: .75rem;
    }
  }

  .warningBox {
    text-align: center;
    position: absolute;
    // bottom: 55%;
    font-size: 0.8rem;
    padding: 2em 3em;
    transform: translate(-50%, 50%);
    width: 90%;
	left: 50%;
    user-select: none;
	background: rgba(255, 255, 255, 0.95);
	border: 1px solid rgba(34,36,38,.15);
	border-radius: 4px;
	box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, 0.2), 1px 3px 15px 2px rgba(0, 0, 0, 0.2);

    @media(max-width: 991px) {
      font-size: .75rem;
    }
  }
  .warningBox > *:nth-child(n - 2) {
	margin-bottom: 0;
  }
  .warningBox-close-icon {
	position: absolute;
	right: 8px;
	top: 8px;
	color: rgba(0,0,0,0.45);  
  }
  
}
