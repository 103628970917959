.power-hours-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.75em;
  border-radius: 6px;
  padding: 0.7rem 1rem;
  cursor: pointer;
  height: 76px;
  justify-content: space-between;
  margin: 7px 0 12px 0;
}
.power-hours-banner .power-hours-icon-container {
  line-height: 0;
}
.power-hours-banner a {
  color: white;
}
.power-hours-banner b {
  margin-left: 0.9em;
  margin-right: 0.8em;
}

.power-hours-offer-button {
  display: flex;
  flex-direction: row;
  width: 100px;
  text-align: center;
  font-size: 12px;
  line-height: 1.2em;
  border: 1px solid #F78F28;
  border-radius: 8px;
  padding: 0.5rem;
  text-transform: uppercase;
  font-family: "graphik-medium";
}


