.ui.modal.splash-screen-modal .splash-screen-modal-icon {
  width: 70px;
  margin: 1em auto 1em;
  line-height: 0;
  text-align: center;
}
.ui.modal.splash-screen-modal h2 {
  text-align: center;
  margin: 0 auto 0.6em;
}
.ui.modal.splash-screen-modal.ui.modal .description {
  padding: 1rem 0;
}
.ui.modal.splash-screen-modal.ui.modal .actions {
  padding-bottom: 0;
}
.ui.modal.splash-screen-modal p {
  margin-bottom: 0.5em;
}
.ui.modal.splash-screen-modal.ui.modal > .actions {
  text-align: center;
}
.ui.modal.splash-screen-modal .splash-screen-remind-me {
  display: inline-block;
  margin-bottom: 1em;
  text-decoration: none;
}
.ui.modal.splash-screen-modal .splash-screen-viewed-count {
  text-align: center;
  font-size: 0.8em;
  margin-bottom: 1.5em;
}
.ui.modal.splash-screen-modal.template-orange {
  border-radius: 9px;
  background: #F7902B;
}
.ui.modal.splash-screen-modal.template-orange > .icon:first-child + *, .ui.modal.splash-screen-modal.template-orange > :first-child:not(.icon) {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}
.ui.modal.splash-screen-modal.template-orange > .content, .ui.modal.splash-screen-modal.template-orange > .actions {
  background: #F7902B;
}
.ui.modal.splash-screen-modal.template-orange h2, .ui.modal.splash-screen-modal.template-orange p, .ui.modal.splash-screen-modal.template-orange div, .ui.modal.splash-screen-modal.template-orange a {
  color: white;
}
.ui.modal.splash-screen-modal.template-orange .description a {
  text-decoration: underline;
}
.ui.modal.splash-screen-modal.template-orange .button {
  border-radius: 23px;
  border: 1px solid white;
  font-weight: 500;
}
.ui.modal.splash-screen-modal.template-orange .splash-screen-remind-me {
  margin: 1rem auto 2rem;
}


