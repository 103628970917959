.ui.menu.sidebar {
	display: flex;
	flex-direction: column;
	justify-content: flex-start; 
	height: 100vh; 

	.item {
	  border: none;
	  border-bottom: none;
	  border-radius: 5px;
	  overflow: hidden;
	  margin: 0 0 10px 0;
	  padding: 12px 15px; 
	  flex-shrink: 0; 
	}
	.item:first-child {
	}
	.sidebar-menu-header {
		font-size: 1.2em;
		font-family: "graphik-medium";
		padding: 0 0 20px 0;
		margin: 10px 0 10px 0;
		background: none!important;
	  } 
  
	.menu-item-content {
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	  width: 100%;
	  padding: 0; 
	}
  
	.menu-item-text {
	  display: flex;
	  flex-direction: column;
	}
  
	.sub-text {
	  color: white;
	  font-size: 0.8em;
	  margin-top: 5px;
	}
  
	.sub-item {
	  padding-left: 0px;
	  padding-right: 0;
	  margin: 0;
	  &:first-child {
		margin-top: 15px;
	  }
	}
  
	.expander {
	  .menu-sub-items {
		overflow: hidden;
		max-height: 0;
		transition: max-height 0.5s ease-out;
	  }
  
	  &.expanded .menu-sub-items {
		max-height: 500px; 
	  }
	}
  
	.sidemenu-row {
	  display: flex;
	  justify-content: space-between;
	  .item {
		flex: 0.48 2;
		padding: 12px 15px; 
	  }
	}
  
	.toggle-button {
	  .menu-item-content {
		flex-wrap: wrap;
		.menu-item-text {
		  order: 2;
		  width: 100%;
		  margin-top: 15px;
		}
	  }
	}
  
	.aurora-energy-section {
	  
	  text-align: left;
	  padding:10px 0;	
	  p {
		margin-bottom: 5px;

	  }
  
	  small {
		display: block;
		margin-top: 5px;
	  }
	}
  }
  a.item.businessLightDarkToggle {
    flex: none !important;
    width: 100% !important;
}